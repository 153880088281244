import React, { useState } from 'react';
import Slider from 'react-slick';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaArrowRight } from 'react-icons/fa';
import devops from './assets/devops.svg';
import webdesign from './assets/web-design.svg'
import appdevelopment from './assets/app-development.svg';
import webdevelopment from './assets/web-development.svg';
import datascience from './assets/data-science.png';
import wordpress from './assets/wordpress.svg';
import react from './assets/reactnative.svg';
import nodejs from './assets/nodejs.svg';
import crm from './assets/crm.png';
import ionic from '../services/assets/ionic.svg';
import xamarin from '../services/assets/xamarin.svg';
import figma from '../services/assets/figma.svg';
import sketch from '../services/assets/sketch.svg';
import xd from '../services/assets/xd.svg';
import photoshop from '../services/assets/photoshop.svg';
import adobeIllustrator from '../services/assets/adobe-Illustrator.svg';
import googlecloud from './assets/googlecloud.svg';
import ai from './assets/aiicon.png';
import deeplearning from './assets/deepearningicon.png';
import machinelearning from './assets/machinelearningicon.png';
import python from '../services/assets/python.svg';
import barchart from './assets/barchart.png';
import calendar from './assets/calendar.png';
import macbookchat from './assets/macbookchat.png';
import process from './assets/process.png';
import sql from './assets/sql.png';
import matlab from './assets/matlab.svg';
import scala from './assets/scala.svg';
import angular from '../services/assets/angular.svg';
import laravel from '../services/assets/laravel.svg';
import vuejs from '../services/assets/vuejs.svg';


function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "none" }}
            onClick={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "none" }}
            onClick={onClick}
        />
    );
}

export default function ServiceSection() {
    const [nav1, setNav1] = useState();
    const [nav2, setNav2] = useState();
    const [expand, updateExpanded] = useState(false);
    const settings = { nextArrow: <SampleNextArrow />, prevArrow: <SamplePrevArrow /> };

    return (
        <section>
            <Container className='business-service-content'>
                <h4 className='section-title'>Services</h4>
                <Row className="justify-content-md-center">
                    <Col className='services-box-main'>
                        <Slider className='service-nav1' asNavFor={nav1} ref={(slider2) => setNav2(slider2)} slidesToShow={3} swipeToSlide={true} focusOnSelect={true} {...settings}>
                            <div>
                                <div className='web-app-service'>
                                    <Col className='service-title'>
                                        <Link className='service-link' as={Link} to="/webapplication" onClick={() => updateExpanded(false)}>
                                            <Col className='services-sub-title'>
                                                <Col className='d-flex align-items-center'>
                                                    <h3>Web <span className='d-block'>Develpoment</span></h3> <img className='service-icon' src={webdevelopment} style={{ height: "15vh" }} />
                                                </Col>
                                                <Col className='d-icon'>
                                                    <FaArrowRight size={18} color='bisque'></FaArrowRight>
                                                </Col>
                                            </Col>
                                        </Link>
                                    </Col>
                                </div>
                            </div>
                            <div>
                                <div className='mobile-app-service'>
                                    <Col className='service-title'>
                                        <Link className='service-link' as={Link} to="/mobileapp" onClick={() => updateExpanded(false)}>
                                            <Col className='services-sub-title'>
                                                <Col className='d-flex align-items-center'>
                                                    <h3>Mobile App <span className='d-block'>Develpoment</span></h3> <img className='service-icon' src={appdevelopment} style={{ height: "15vh" }} />
                                                </Col>
                                                <Col className='d-icon'>
                                                    <FaArrowRight size={18} color='bisque'></FaArrowRight>
                                                </Col>
                                            </Col>
                                        </Link>
                                    </Col>
                                </div>
                            </div>
                            <div>
                                <div className='uiux-desing-service'>
                                    <Col className='service-title'>
                                        <Link className='service-link' as={Link} to="/uiuxdesign" onClick={() => updateExpanded(false)}>
                                            <Col className='services-sub-title'>
                                                <Col className='d-flex align-items-center'>
                                                    <h3>UI/UX <span className='d-block'>Web Design</span></h3> <img className='service-icon' src={webdesign} style={{ height: "15vh" }} />
                                                </Col>
                                                <Col className='d-icon'>
                                                    <FaArrowRight size={18} color='bisque'></FaArrowRight>
                                                </Col>
                                            </Col>
                                        </Link>
                                    </Col>
                                </div>
                            </div>
                            <div>
                                <div className='dev-ops-service'>
                                    <Col className='service-title'>
                                        <Link className='service-link' as={Link} to="/ai" onClick={() => updateExpanded(false)}>
                                            <Col className='services-sub-title'>
                                                <Col className='d-flex align-items-center'>
                                                    <h3>AI <span className='d-block'>Solutions</span></h3> <img className='service-icon' src={ai} style={{ height: "16vh" }} />
                                                </Col>
                                                <Col className='d-icon'>
                                                    <FaArrowRight size={18} color='bisque'></FaArrowRight>
                                                </Col>
                                            </Col>
                                        </Link>
                                    </Col>
                                </div>
                            </div>
                            <div>
                                <div className='crm-web-service'>
                                    <Col className='service-title'>
                                        <Link className='service-link' as={Link} to="/crm" onClick={() => updateExpanded(false)}>
                                            <Col className='services-sub-title'>
                                                <Col className='d-flex align-items-center'>
                                                    <h3>CRM Web <span className='d-block'>Service</span></h3> <img className='service-icon' src={crm} style={{ height: "15vh" }} />
                                                </Col>
                                                <Col className='d-icon'>
                                                    <FaArrowRight size={18} color='bisque'></FaArrowRight>
                                                </Col>
                                            </Col>
                                        </Link>
                                    </Col>
                                </div>
                            </div>
                            <div>
                                <div className='data-science-service'>
                                    <Col className='service-title'>
                                        <Link className='service-link' as={Link} to="/datascience" onClick={() => updateExpanded(false)}>
                                            <Col className='services-sub-title'>
                                                <Col className='d-flex align-items-center'>
                                                    <h3>Data Science <span className='d-block'>Services</span></h3> <img className='service-icon' src={datascience} style={{ height: "15vh" }} />
                                                </Col>
                                                <Col className='d-icon'>
                                                    <FaArrowRight size={18} color='bisque'></FaArrowRight>
                                                </Col>
                                            </Col>
                                        </Link>
                                    </Col>
                                </div>
                            </div>
                        </Slider>

                        <Slider asNavFor={nav2} ref={(slider1) => setNav1(slider1)}>
                            <div>
                                <Row className="justify-content-md-center">
                                    <Col md={8} className='service-slide-content'>
                                        <p>We have expertise in choosing what the best solution is for your mobile app development strategy, whether it's in Flutter, React Native, or native iOS and Android. And expertise in fintech, edtech, healthcare, streaming industries, and more.</p>
                                        <ul className='service-con-icon'>
                                            <li className='service-con-list'><img src={ionic} style={{ height: "15vh" }} /><span className='icon-text d-block'>Ionic</span></li>
                                            <li className='service-con-list'><img src={react} style={{ height: "15vh" }} /><span className='icon-text d-block'>React Native</span></li>
                                            <li className='service-con-list'><img src={xamarin} style={{ height: "15vh" }} /><span className='icon-text d-block'>Xamarin</span></li>                                            
                                        </ul>
                                    </Col>
                                </Row>
                            </div>
                            <div>
                                <Row className="justify-content-md-center">
                                    <Col md={8} className='service-slide-content'>
                                        <p>We create modern, interactive, and reliable user interfaces across all business domains. We work with the most modern tools and frameworks like React.js, Angular, Vue.js, and much more for designing and developing engaging, fast loading, and robust, user-friendly interfaces.</p>
                                        <ul className='service-con-icon'>
                                            <li className='service-con-list'><img src={figma} style={{ height: "13vh" }} /><span className='icon-text d-block'>Figma</span></li>
                                            <li className='service-con-list'><img src={xd} style={{ height: "13vh" }} /><span className='icon-text d-block'>XD</span></li>
                                            <li className='service-con-list'><img src={sketch} style={{ height: "13vh" }} /><span className='icon-text d-block'>Sketch</span></li>
                                            <li className='service-con-list'><img src={photoshop} style={{ height: "13vh" }} /><span className='icon-text d-block'>PhotoShop</span></li>
                                            <li className='service-con-list'><img src={adobeIllustrator} style={{ height: "13vh" }} /><span className='icon-text d-block'>Adobe Illustrator</span></li>
                                        </ul>
                                    </Col>
                                </Row>
                            </div>
                            <div>
                                <Row className="justify-content-md-center">
                                    <Col md={8} className='service-slide-content'>
                                        <p>Deliver maximum impact across all domains, cut down costs and simplify processes with the powerful systems supportted by Artificial Intelligence (AI). Exprience the power of AI business solutions Now! Through AI solutions, companies can strike thats balance to support human efforts through automated means.</p>
                                        <ul className='service-con-icon'>
                                        <li className='service-con-list'><img src={machinelearning} style={{ height: "15vh" }} /><span className='icon-text d-block'>Machine Learning</span></li>
                                            <li className='service-con-list'><img src={deeplearning} style={{ height: "15vh" }} /><span className='icon-text d-block'>Deep Learning</span></li>
                                            <li className='service-con-list'><img src={googlecloud} style={{ height: "15vh" }} /><span className='icon-text d-block'>Google Cloud</span></li>
                                            <li className='service-con-list'><img src={python} style={{ height: "15vh" }} /><span className='icon-text d-block'>Python</span></li>                                            
                                        </ul>
                                    </Col>
                                </Row>
                            </div>
                            <div>
                                <Row className="justify-content-md-center">
                                    <Col md={8} className='service-slide-content'>
                                        <p>Integrate, grow and maintain your marketing campaigns with leading CRMs. Is a fully-integrated CRM platform which helps businesses maximize revenue through analytics, flexible billing, risk management and more!</p>
                                        <ul className='service-con-icon'>
                                        <li className='service-con-list'><img src={process} style={{ height: "15vh" }} /><span className='icon-text d-block'>Process Transformation</span></li>
                                            <li className='service-con-list'><img src={macbookchat} style={{ height: "15vh" }} /><span className='icon-text d-block'>Live Chat</span></li>
                                            <li className='service-con-list'><img src={barchart} style={{ height: "15vh" }} /><span className='icon-text d-block'>Gap Analysis</span></li>
                                            <li className='service-con-list'><img src={calendar} style={{ height: "15vh" }} /><span className='icon-text d-block'>Calendar</span></li>
                                        </ul>
                                    </Col>
                                </Row>
                            </div>
                            <div>
                                <Row className="justify-content-md-center">
                                    <Col md={8} className='service-slide-content'>
                                        <p>Harness the power of Big Data to unveil hidden trends and patterns, while collecting insights that drive innovation. From predicting consumer demand, modelling voting behaviour to supporting Doctors in early diagnosis of deaseas; millions of businesses departments rely on Big Data to succeed and better serve their customers.</p>
                                        <ul className='service-con-icon'>
                                        <li className='service-con-list'><img src={python} style={{ height: "15vh" }} /><span className='icon-text d-block'>Python</span></li>
                                            <li className='service-con-list'><img src={scala} style={{ height: "15vh" }} /><span className='icon-text d-block'>Scala</span></li>
                                            <li className='service-con-list'><img src={sql} style={{ height: "15vh" }} /><span className='icon-text d-block'>SQL</span></li>
                                            <li className='service-con-list'><img src={matlab} style={{ height: "15vh" }} /><span className='icon-text d-block'>Matlab</span></li>
                                        </ul>
                                    </Col>
                                </Row>
                            </div>
                            <div>
                                <Row className="justify-content-md-center">
                                    <Col md={8} className='service-slide-content'>
                                        <p>We use state-of-the-art technologies and development practices to create custom web applications for your business. Our web design team conceptualizes and creates each application from scratch, ensuring it meets your unique business.</p>
                                        <ul className='service-con-icon'>
                                        <li className='service-con-list'><img src={wordpress} style={{ height: "15vh" }} /><span className='icon-text d-block'>Wordpress</span></li>
                                            <li className='service-con-list'><img src={nodejs} style={{ height: "15vh" }} /><span className='icon-text d-block'>Node Js</span></li>
                                            <li className='service-con-list'><img src={laravel} style={{ height: "15vh" }} /><span className='icon-text d-block'>Laravel</span></li>
                                            <li className='service-con-list'><img src={react} style={{ height: "15vh" }} /><span className='icon-text d-block'>React Js</span></li>
                                            <li className='service-con-list'><img src={angular} style={{ height: "15vh" }} /><span className='icon-text d-block'>Angular Js</span></li>
                                            <li className='service-con-list'><img src={vuejs} style={{ height: "15vh" }} /><span className='icon-text d-block'>Vue Js</span></li>
                                        </ul>
                                    </Col>
                                </Row>
                            </div>
                            
                        </Slider>
                    </Col>
                    <Col md={10} className='service-btn'>
                        <Link className='glow-on-hover btn btn-default btn-oval btn-xl' as={Link} to="/services" onClick={() => updateExpanded(false)}>All Services <FaArrowRight size={12} color='bisque'></FaArrowRight></Link>
                    </Col>
                </Row>
            </Container>
        </section >
    );

}