import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaArrowRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Uiuxanimation from './Uiuxanimation';
import figma from './assets/figma.svg';
import photoshop from './assets/photoshop.svg';
import adobeIllustrator from './assets/adobe-Illustrator.svg';
import aftereffects from './assets/after-effects.svg';
import sketch from './assets/sketch.svg';
import xd from './assets/xd.svg';


export default function UIUXDesign() {
    const [expand, updateExpanded] = useState(false);
    
    return (
        <section>
            <Container className='uiuxdesign-content'>
                <Row className="justify-content-md-center">
                    <Col md={12} className="uiuxdesign">
                        <Row className="justify-content-md-center">
                            <Col md={6} className='uxui-contant'>
                                <Row className="justify-content-md-center">
                                    <Col md={6} className='uiux-design'>
                                        <Col>
                                            <ul className='uiux-icon'>
                                                <li className='uiux-icon-list'><img className="uxui-icon-img1 fluid" src={figma} /></li>
                                                <li className='uiux-icon-list'><img className="uxui-icon-img1 fluid" src={sketch} /></li>
                                                <li className='uiux-icon-list'><img className="uxui-icon-img1 fluid" src={xd} /></li>                                                
                                            </ul>
                                        </Col>
                                        <h4>UI/UX Design</h4>
                                        <Col className='design-types'>
                                            <h4>Interface Design</h4>
                                            <h4>Interaction Design</h4>
                                            <h4>Research</h4>
                                            <h4>Prototyping</h4>                                            
                                        </Col>
                                        <Link className='btn btn-default btn-circle btn-xl' as={Link} to="/uiuxdesign" onClick={() => updateExpanded(false)}><FaArrowRight size={20} color='bisque' /></Link>
                                    </Col>
                                    <Col md={6} className='graphic-design'>
                                        <Col>
                                            <ul className='graphic-icon'>
                                                <li className='graphic-icon-list'><img className="graphic-icon-img1 fluid" src={adobeIllustrator} /></li>
                                                <li className='graphic-icon-list'><img className="graphic-icon-img1 fluid" src={photoshop} /></li>
                                                <li className='graphic-icon-list'><img className="graphic-icon-img1 fluid" src={aftereffects} /></li>
                                            </ul>
                                        </Col>
                                        <h4>Graphic Design</h4>
                                        <Col className='design-types'>
                                            <h4>Illustration Design</h4>
                                            <h4>Motion Graphics</h4>
                                            <h4>Animations Design</h4>
                                            <h4>Logo Design</h4>                                            
                                        </Col>
                                        <Link className='btn btn-default btn-circle btn-xl' as={Link} to="/uiuxdesign" onClick={() => updateExpanded(false)}><FaArrowRight size={20} color='bisque' /></Link>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={6} className='uxui-paralax'>
                                <Uiuxanimation />
                            </Col>
                        </Row>                        
                    </Col>
                </Row>
            </Container>
        </section>
    );

}