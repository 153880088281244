import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Title from '../Header/Title';
import SolutionHire from '../Contact/SolutionHire';
import ProjectDiscuss from '../Contact/ProjectDiscuss';
import ProductHire from '../Contact/ProductHire';
import TrendingTech from '../Home/TrendingTech';
import TechnologyExpert from '../Home/TechnologyExpert';
import ChooseUs from './ChooseUs';
import Testimonial from '../Testimonial';
import NewsLetter from '../Newsletter/NewsLetter';
import { FaArrowRight } from 'react-icons/fa';
import { FaAws } from 'react-icons/fa';
import { SiGooglecloud } from 'react-icons/si';
import { SiKubernetes } from 'react-icons/si';
import { SiMicrosoftazure } from 'react-icons/si';
import server from '../assets/server.png';
import cloudapp from '../assets/cloudapp.png';
import configuration from '../assets/configuration.png';
import integration from '../assets/integration.png';
import security from '../assets/security.png';
import migration from '../assets/migration.png';




export default function Cloud() {

    const [expand, updateExpanded] = useState(false);
    const webcontant = [
        { id: 1, name: 'Cloud App Development', para: 'Our developers have experience building websites for almost every industry and virtually every platform, including WordPress, Drupal, and Joomla.', link: '#', icon: cloudapp },
        { id: 2, name: 'Cloud Configuration', para: 'We work with modern frameworks like React.js, Angular, Vue.js, Nuxt, Next, and Gatsby to create next-generation user experiences that integrate and interact seamlessly with your database and APIs.', link: '#', icon: configuration },
        { id: 3, name: 'Cloud Integration', para: 'Leverage our expertise with Cloud, DevOps, PHP, Node.js, Python, .NET, Java, and RoR to build custom, secure and reliable backends and APIs for web or mobile apps across multiple domains.', link: '#', icon: integration },
        { id: 4, name: 'Cloud security', para: 'If you have a store or need a new one, our web application development services can surely help you. We can create a store for you from scratch or leverage popular platforms such as WooCommerce, Shopify, or Magento.', link: '#', icon: security },
        { id: 5, name: 'Cloud Server Solutions', para: 'As a web application development company, we have built all sorts of web applications, from online forms and workflows to whole management systems. We work on projects both big and small.', link: '#', icon: server },
        { id: 6, name: 'Cloud Migration', para: 'Do you have a manual process that you need to automate? Our web application development team can develop a customized, secure, and scalable system as per your business needs.', link: '#', icon: migration }
       
    ]
    return (
        <section>
            <Container className="solutionoverview-content">
                <Title>Cloud Computing</Title>
                <Col className='inerpage-title'>
                    <h4 className='section-title'>What we do</h4>
                </Col>
                <Row className="justify-content-md-center">
                    <Col md={5} className='solutionoverview-title'>
                        <Row>
                            <Col className='solutionover-hedding'>
                                <h4>Providing secured cloud computing solutions to host and maintain your data.</h4>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='solutionover-para'>
                                <p>To revolutionize your business with exclusive app solutions, you must need on-demand access to all of your tools and apps. However, you might be worried about technical issues and maintenance of equipment that take a lot of money and time. Fortunately, you can lay your hands on our cloud computing solutions to get a secure place for hosting your infrastructure, programs, and data.</p>
                            </Col>
                        </Row>

                    </Col>
                    <Col md={5} className='solutionoverview-icons'>
                        <Row>
                            <Col md={6} className='solution-icon-one'>
                                <Row>
                                    <Col className='solution-enterprise-one'>
                                        <FaAws size={65} style={{ color: "white" }} />
                                        <h4>AWS</h4>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='solution-enterprise-two'>
                                        <SiMicrosoftazure size={65} style={{ color: "white" }} />
                                        <h4>Microsoft Azure</h4>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={6} className='solution-icon-two'>
                                <Row>
                                    <Col className='solution-enterprise-three'>
                                        <SiGooglecloud size={65} style={{ color: "white" }} />
                                        <h4>Google Cloud</h4>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='solution-enterprise-fore'>
                                        <SiKubernetes size={65} style={{ color: "white" }} />
                                        <h4>Kubernetes</h4>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
            <Container className="page-mid-container">
                <Row className="justify-content-md-center">
                    <Col md={10} className='page-mid-title'>
                        <h4>Scalable Cloud Computing Services</h4>
                        <p>
                        Improved efficiency, faster communication and a greater ROI. That’s just some of what we can make your backend enterprise apps do for you. Our team develops solutions for your entire organization through consistent, scalable and adaptable platforms.
                        </p>
                    </Col>
                    <Col md={10} className='page-mid-contant'>
                        <Row style={{ justifyContent: "center" }}>
                            {
                                webcontant.map((item, i) => <Col sm={4} className="page-mid-box" key={i}>
                                    <img className='page-icon-img' src={item.icon} style={{ height: "13vh" }} />
                                    <h5>{item.name}</h5>
                                    <p>{item.para}</p>
                                    <Link className='glow-on-hover btn btn-default btn-oval btn-xl' as={Link} to="/contact" onClick={() => updateExpanded(false)}>Learn More  <FaArrowRight size={12} color='bisque' /></Link>
                                </Col>)
                            }
                        </Row>
                    </Col>
                    <Col md={10}>
                        <Link className='glow-on-hover btn btn-default btn-oval btn-xl' as={Link} to="/contact" onClick={() => updateExpanded(false)}>Let's Discuss  <FaArrowRight size={12} color='bisque' /></Link>
                    </Col>
                </Row>
            </Container>
            <ProductHire />
            <TechnologyExpert />
            <ChooseUs />
            <TrendingTech />
            <SolutionHire />
            <NewsLetter />
            <Testimonial />
            <ProjectDiscuss />
        </section>
    );

}