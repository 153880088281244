import { FaFacebookF } from 'react-icons/fa';
import { FaInstagram } from 'react-icons/fa';
import { FaLinkedinIn } from 'react-icons/fa';
import { FaTwitter } from 'react-icons/fa';
import { FaYoutube } from 'react-icons/fa';


export default function SocialLists() {

    return (
        <ul className="list-inline social-buttons">            
            <li className="list-inline-item icon-item">
                <a href="https://www.facebook.com/mesdtechnology" target="_blank"><FaFacebookF size={20} /></a>
            </li>
            <li className="list-inline-item icon-item">
                <a href="https://www.instagram.com/mesdtech/" target="_blank"><FaInstagram size={20} /></a>
            </li>
            <li className="list-inline-item icon-item">
                <a href="https://twitter.com/mesdtech" target="_blank"><FaTwitter size={20} /></a>
            </li>
            <li className="list-inline-item icon-item">
                <a href="https://www.linkedin.com/company/mesdtech/" target="_blank"><FaLinkedinIn size={20} /></a>
            </li>
            <li className="list-inline-item icon-item">
                <a href="https://www.youtube.com/channel/UCLjhK6XvHyElYwSJ-cDnFMg" target="_blank"><FaYoutube size={20} /></a>
            </li>
        </ul>
    );
}