import React, { useEffect, useState } from "react";
import styled from "styled-components";
import times from "lodash/times";
import Marquee from "react-marquee-slider";
import { withSize } from "react-sizeme";
import { nanoid } from "nanoid";
import { Row, Col } from "react-bootstrap";

import visualstudio from "./assets/visualstudio.png";
import nodejs from "./assets/nodejs.png";
import react from "./assets/react.png";
import Wordpress from "./assets/wordpress.png";
import MySQL from "./assets/mySQL.png";
import angularjs from "./assets/angularjs.png";
import laravel from "./assets/laravel.png";
import codeIgniter from "./assets/codeIgniter.png";
import woocommerce from "./assets/woocommerce.png";
import shopify from "./assets/shopify.png";
import xamarin from "./assets/xamarin.png";
import ionic from "./assets/ionic.png";
import jquery from "./assets/jquery.png";
import javascript from "./assets/javascript.png";
import typescript from "./assets/typescript.png";
import vuejs from "./assets/vuejs.png";

// file size must be 110x80px

function TechnologyExpert({ size }) {
    const Photo = styled.img`
  width: ${(props) => props.scale * 138}px;
  height: ${(props) => props.scale * 100}px;
  border-radius: 4px;
  box-shadow: 0 7px 20px 0 rgba(0, 0, 0, 0.12);
  object-fit: cover;
  object-position: top;
  margin-left: ${(props) => (props.offset === "true" ? props.scale * 7 : props.scale * 87)}px;
  margin-right: ${(props) => (props.offset === "true" ? props.scale * 80 : 0)}px;
`;
    const photos = [
        Wordpress,
        nodejs,
        react,
        visualstudio,
        MySQL,
        angularjs,
        laravel,
        codeIgniter,
        woocommerce,
        shopify,
        xamarin,
        ionic,
        jquery,
        javascript,
        typescript,
        vuejs,

    ];
    

    const [key, setKey] = useState(nanoid());

    useEffect(() => {
        setKey(nanoid());
    }, [size, size.width]);

    let scale = 0.5;

    if (size && size.width > 800) {
        scale = 0.65;
    }

    if (size && size.width > 1100) {
        scale = 0.8;
    }

    if (size && size.width > 1400) {
        scale = 1;
    }

    return (
        <Row>
            <h4 className="section-title">Our Technology Expertise</h4>
            <div style={{ height: scale * 130 }}>
                <Marquee key={key} velocity={25}>
                    {times(16, Number).map((id) => (
                        <Photo src={photos[id]} alt="" key={`marquee-example-people-${id}`} scale={scale} />                        
                    ))}
                </Marquee>
            </div>    

        </Row>
    );
}
export default withSize()(TechnologyExpert);