import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Title from '../Header/Title';
import TechExpert from '../Home/TechExpert';
import ChooseUs from './ChooseUs';
import Testimonial from '../Testimonial';
import NewsLetter from '../Newsletter/NewsLetter';
import ProjectDiscuss from '../Contact/ProjectDiscuss';

export default function Ecommerce() {

    return (
        <section>
            <Container fluid className="page-design-content" id="page">
                <Title>E-Commerce</Title>
                <Col className='inerpage-title'>
                    <h4 className='section-title'>What we do</h4>
                </Col>
                <Row className="justify-content-md-center">
                    <Col md={8} className='page-design-title'>
                        <h4>Explore the Powerful Tide of our Comprehensive Web and Mobile E-commerce Services.</h4>
                        <p>Save up to 50% of your development costs and win more conversions with MESD Technology’ customer-focused eCommerce solutions. We help store owners, retailers, suppliers, and wholesalers digitally expand with stunning online marketplaces that reach every customer - on the web and mobile.</p>
                        <p>Your online digital catalyst is the master craftsmen in creating outstanding and unique mobile e-commerce solutions to put your business in the driving seat. Streamline your business requirements and boost your relationship with potential customers to create healthy opportunities with expert tips on Customer Relationship Management (CRM) system; a great asset for expanding the business prospects.</p>
                    </Col>
                    <Col md={6} className='page-design-mid'>
                        <h5>Delve Your Business Deep into Digital E-commerce & Retail Pool</h5>
                        <p>With integrated capabilities, we create eCommerce platforms that are income-generating and leading-edge. From frontend, backend, code, tests, launch to support - we tackle everything with finesse so that you bring in revenue like clockwork. Our WooCommerce, Magento, and Shopify development services lift businesses to the next level. Whether you have an entire store setup, high customizations, or a mere upgrade in mind, we can take care of it all to improve your bottom line.</p>
                        <Col md={8} className='design-types'>
                            <h4>eCommerce Website Development</h4>
                            <h4>Multi-Vendor Marketplace Development</h4>
                            <h4>Shopify eCommerce Development</h4>
                            <h4>WooCommerce Store Development</h4>
                            <h4>B2B eCommerce Development</h4>
                            <h4>Multichannel eCommerce Development</h4>
                            <h4>eCommerce Marketplace Development</h4>
                            <h4>Custom Integrations</h4>
                            <h4>eCommerce Migration and Maintenance</h4>                            
                        </Col>
                    </Col>
                </Row>
                <ProjectDiscuss />
                <ChooseUs />
                <TechExpert />
                <NewsLetter />
                <Testimonial />
            </Container>            
        </section>
    );

}