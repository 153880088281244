import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { MdAddCall, MdEmail } from "react-icons/md";
import { FaFacebookF } from 'react-icons/fa';
import { FaInstagram } from 'react-icons/fa';
import { FaLinkedinIn } from 'react-icons/fa';
import { FaTwitter } from 'react-icons/fa';
import { FaYoutube } from 'react-icons/fa';

export default function TopBar() {

    return (
        <Col md={12} className='topbar'>
            <Row>
                <Col md={6} className='top-contact'>
                    <ul className='list-inline top-contact-list'>
                        <li className='list-inline top-oncontact'>
                            <span><MdAddCall size={25} />   <a href="hello@mesdtech.com" style={{ color: "white" }} target="_blank" rel="noopener noreferrer">
                                +91-983-659-0025
                            </a></span>
                        </li>
                        <li className='list-inline top-oncontact'>
                            <span><MdEmail size={25} />   <a href="#" style={{ color: "white" }} target="_blank" rel="noopener noreferrer">
                                hello@mesdtech.com
                            </a></span>
                        </li>
                    </ul>
                </Col>
                <Col md={6} className='top-social'>
                    <ul className="list-inline top-social-buttons">
                        <li className="list-inline-item icon-item">
                            <a href="https://www.facebook.com/mesdtechnology" target="_blank"><FaFacebookF size={18} /></a>
                        </li>
                        <li className="list-inline-item icon-item">
                            <a href="https://www.instagram.com/mesdtech/" target="_blank"><FaInstagram size={18} /></a>
                        </li>
                        <li className="list-inline-item icon-item">
                            <a href="https://twitter.com/mesdtech" target="_blank"><FaTwitter size={18} /></a>
                        </li>
                        <li className="list-inline-item icon-item">
                            <a href="https://www.linkedin.com/company/mesdtech/" target="_blank"><FaLinkedinIn size={18} /></a>
                        </li>
                        <li className="list-inline-item icon-item">
                            <a href="https://www.youtube.com/channel/UCLjhK6XvHyElYwSJ-cDnFMg" target="_blank"><FaYoutube size={18} /></a>
                        </li>
                    </ul>
                </Col>
            </Row>
        </Col>

    );

}