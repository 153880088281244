import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Title from '../Header/Title';
import ChooseUs from './ChooseUs';
import Testimonial from '../Testimonial';
import NewsLetter from '../Newsletter/NewsLetter';
import SolutionHire from '../Contact/SolutionHire';
import ProjectDiscuss from '../Contact/ProjectDiscuss';
import ProductHire from '../Contact/ProductHire';
import TrendingTech from '../Home/TrendingTech';
import TechnologyExpert from '../Home/TechnologyExpert';
import { FaArrowRight } from 'react-icons/fa';
import { FaChartPie } from 'react-icons/fa';
import { FaRegCalendarAlt } from 'react-icons/fa';
import { FaRegHourglass } from 'react-icons/fa';
import { FaRegPaperPlane } from 'react-icons/fa';
import charts from '../assets/chart.png';
import process from '../assets/iot.png';
import app from '../assets/consumer.png';
import selection from '../assets/cms.png';
import design from '../assets/webapplication.png';



export default function Crm() {

    const [expand, updateExpanded] = useState(false);
    const webcontant = [
        { id: 1, name: 'Gap Analysis', para: 'Quantify and analyze graps between exiting system and future growth prospects and prioritize actions. We conduct in-depth analysis of your existing process, your business goals, apps runing and formulate a consolidated report that can be benefical while crafting growth-focused business processess, application development and enterprise app installation.', link: '#', icon: charts },
        { id: 2, name: 'Solution Design', para: 'Find solutions that are bit fit with your organization and decision-making processes, save time and money with working solutions. We offer oppropriate design solutions by working in close collaboration with your internal teams. Our desing teams start with several options and assist you in choosing the most appropriate solution according to your business prospect.', link: '#', icon: design },
        { id: 3, name: 'Solution Selection', para: 'CRM Consultants from MESD Technology will help you find solutions that actually fit your organization and decision-making process. Out of all application type we suggest only the ones that will work for your specific business. This helps save time and money, and makes sure you are presented working solutions.', link: '#', icon: selection },
        { id: 4, name: 'Process Transformation', para: 'Modernize tour existing business processes to attract new streams of revenue. Radixwebs CRM experts are adept in identifying the lags in your business process and will help your transform legacy business processes by integrating new perspective to drive your business goals. Expect a full spectrum of supports and transformation services to help you make a shift.', link: '#', icon: process },
        { id: 5, name: 'App Modernization', para: 'Get done with outdated apps that carry security and privacy risks. Uncover your true sales potential with a modernized take on exisiting apps. Our experts will boost your client relationship rates by modifying or entirely rebuilding legacy apps by upgrading your tools to boost your enterprise process and get your business ready to face the challenges of growth.', link: '#', icon: app }        
    ]
    return (
        <section>
            <Container className="solutionoverview-content">
                <Title>CRM Consultant</Title>
                <Col className='inerpage-title'>
                    <h4 className='section-title'>What we do</h4>
                </Col>
                <Row className="justify-content-md-center">
                    <Col md={5} className='solutionoverview-title'>
                        <Row>
                            <Col className='solutionover-hedding'>
                                <h4>Level-up your customer satisfaction to ensure a smooth business process</h4>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='solutionover-para'>
                                <p>A Customer Relation Management solution is the baseline for an enterprise interaction with exisiting and probabel customers. Ite channelizes sales growth through data analysis from various communication channels like websites, telephonic interactions, email conversation, live chats, marketing drives and social media.</p>
                            </Col>
                        </Row>

                    </Col>
                    <Col md={5} className='solutionoverview-icons'>
                        <Row>
                            <Col md={6} className='solution-icon-one'>
                                <Row>
                                    <Col className='solution-enterprise-one'>
                                        <FaRegCalendarAlt size={65} style={{ color: "white" }} />
                                        <h4>Calendar</h4>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='solution-enterprise-two'>
                                        <FaChartPie size={65} style={{ color: "white" }} />
                                        <h4>Pie Charts</h4>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={6} className='solution-icon-two'>
                                <Row>
                                    <Col className='solution-enterprise-three'>
                                        <FaRegHourglass size={65} style={{ color: "white" }} />
                                        <h4>Process</h4>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='solution-enterprise-fore'>
                                        <FaRegPaperPlane size={65} style={{ color: "white" }} />
                                        <h4>Live Chat</h4>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
            <Container className="page-mid-container">
                <Row className="justify-content-md-center">
                    <Col md={10} className='page-mid-title'>
                        <h4>Create meaningful customer experiences by turing your data</h4>
                        <p>
                            Customer Relation Management solution render a unified platform for tracking and anaging customers across several channels with ease. We bring all customer focused data on a unified location to provide end-to-end visibility on clientele while offering easy access of all data to internal teams for indentifying leads, contacts and engage, track and manage them efficiently.
                        </p>
                    </Col>
                    <Col md={10} className='page-mid-contant'>
                        <Row style={{ justifyContent: "center" }}>
                            {
                                webcontant.map((item, i) => <Col sm={4} className="page-mid-box" key={i}>
                                    <img className='page-icon-img' src={item.icon} style={{ height: "13vh" }} />
                                    <h5>{item.name}</h5>
                                    <p>{item.para}</p>
                                    <Link className='glow-on-hover btn btn-default btn-oval btn-xl' as={Link} to="/contact" onClick={() => updateExpanded(false)}>Learn More  <FaArrowRight size={12} color='bisque' /></Link>
                                </Col>)
                            }
                        </Row>
                    </Col>
                    <Col md={10}>
                        <Link className='glow-on-hover btn btn-default btn-oval btn-xl' as={Link} to="/contact" onClick={() => updateExpanded(false)}>Let's Discuss  <FaArrowRight size={12} color='bisque' /></Link>
                    </Col>
                </Row>
            </Container>
            <ProductHire />
            <ChooseUs />
            <TechnologyExpert />
            <TrendingTech />
            <ProjectDiscuss />
            <NewsLetter />
            <Testimonial />
            <SolutionHire />
        </section>
    );

}