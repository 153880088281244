import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Title from '../Header/Title';
import TechExpert from '../Home/TechExpert';
import ChooseUs from './ChooseUs';
import Testimonial from '../Testimonial';
import NewsLetter from '../Newsletter/NewsLetter';
import ProjectDiscuss from '../Contact/ProjectDiscuss';

export default function FoodDelivery() {

    return (
        <section>
            <Container fluid className="page-design-content" id="page">
                <Title>Food Delivery</Title>
                <Col className='inerpage-title'>
                    <h4 className='section-title'>What we do</h4>
                </Col>
                <Row className="justify-content-md-center">
                    <Col md={8} className='page-design-title'>
                        <h4>Stimulate Your Business Forward with On-Demand Food Delivery Solutions.</h4>
                        <p>Drive your potential customers into a comfort zone where ordering online food becomes a cakewalk with complete digital synced solutions in food delivery.</p>
                        <p>With the on-demand food delivery app and services gaining the momentum, you have to inject the digital adrenaline to put your business on the fast forward moving scale. MindInventory is a reliable digital caterer that blends innovation with elegance and experience to create splendid on-demand food delivery apps and solutions.</p>
                    </Col>
                    <Col md={6} className='page-design-mid'>
                        <h5>Robust On Demand Food Delivery App Development</h5>
                        <p>With deep experience in food delivery mobile app development for businesses of all sizes, we can help you begin right. Our experienced food delivery app developers will help you balance consumer preferences with your requirements and develop a food delivery app that marks your presence in the food delivery business scenario. Partner with the best food delivery app development company and reach your tech-savvy consumer-base, serve local and beyond with:</p>
                        <Col md={8} className='design-types'>
                            <h4>Online slot reservation</h4>
                            <h4>Online promotion throug 'check in' Apps</h4>
                            <h4>Digital menu card</h4>
                            <h4>Online reviews and ratings</h4>
                            <h4>Online ordering and delivery</h4>
                            <h4>Easy onboarding of customers</h4>                             
                        </Col>
                    </Col>
                </Row>
                <ProjectDiscuss />
                <ChooseUs />
                <TechExpert />
                <NewsLetter />
                <Testimonial />
            </Container>            
        </section>
    );

}