import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Title from '../Header/Title';
import TechExpert from '../Home/TechExpert';
import ChooseUs from './ChooseUs';
import Testimonial from '../Testimonial';
import NewsLetter from '../Newsletter/NewsLetter';
import ProjectDiscuss from '../Contact/ProjectDiscuss';

export default function VueJs() {

    return (
        <section>
            <Container fluid className="page-design-content" id="page">
                <Title>Vue Js</Title>
                <Col className='inerpage-title'>
                    <h4 className='section-title'>What we do</h4>
                </Col>
                <Row className="justify-content-md-center">
                    <Col md={8} className='page-design-title'>
                        <h4>We help businesses to build high-performance MVC capable apps using VueJS.</h4>
                        <p>As an early VueJS adoption, we can provide you VueJS development services with efficiency. Our team of experienced Vue JS developers utilize reusable, component based UI so it becomes easy to upscale the applications in the future.</p>                        
                    </Col>
                    <Col md={6} className='page-design-mid'>
                        <h5>Our High Quality VueJs Development Services</h5>
                        <p>Vue.js is an open-source JavaScript framework for building user interfaces. It is Progressive JavaScript Framework and the core library is focused on the view layer only. Using Vus.js, we can develop advanced single page web apps and mobile applications.</p>
                        <Col md={8} className='design-types'>
                            <h4>Custom App Development</h4>
                            <h4>Portal Development</h4>
                            <h4>Front-End Development</h4>
                            <h4>Real-Time App Development</h4>
                            <h4>Single Page App Development</h4>                            
                            <h4>API Development</h4>
                            <h4>Migration Services</h4>                            
                        </Col>
                    </Col>
                </Row>
                <ProjectDiscuss />
                <ChooseUs />
                <TechExpert />
                <NewsLetter />
                <Testimonial />
            </Container>            
        </section>
    );

}