import React from 'react';
import Slider from "react-slick";
import { FaQuoteLeft, FaQuoteRight } from 'react-icons/fa';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import logo from './assets/react.png';


const ReviewBox = ({ image, name, expert, review }) => (
    <div className="testimonial__div mx-2 px-3 pb-3 o-border-purple d-flex flex-column position-relative">
        <div className="fa-quote-left text-white o-font-lg text-center o-bg-purple position-absolute rounded-circle">
            <FaQuoteLeft />
        </div>
        <div className='review-img text-center o-bg-black rounded-circle position-absolute'>
            <img className='slideimg' src={image} style={{ height: "10vh" }} />
        </div>
        <strong className='text-st'>{name}</strong>
        <small className="text-muted mb-3">{expert}</small>
        <p className="text-justify o-font-sm">{review}</p>

    </div>
);

class Testimonial extends React.Component {

    state = {
        testimonials: [
            {
                id: 1,
                image: logo,
                name: 'David Joe',
                expert: 'Client',
                review: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et.',
            },
            {
                id: 2,
                image: logo,
                name: 'Jenifer Doe',
                expert: 'Graphist & Designer',
                review: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et.',
            },
            {
                id: 3,
                image: logo,
                name: 'Mohammad Oftadeh',
                expert: 'Software Engineer',
                review: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et.',
            },
            {
                id: 4,
                image: logo,
                name: 'Mohammad Oftadeh4',
                expert: 'Software Engineer',
                review: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et.',
            },
            {
                id: 5,
                image: logo,
                name: 'Mohammad Oftadeh5',
                expert: 'Software Engineer',
                review: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et.',
            },
            {
                id: 6,
                image: logo,
                name: 'Mohammad Oftadeh6',
                expert: 'Software Engineer',
                review: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et.',
            },
            {
                id: 7,
                image: logo,
                name: 'Mohammad Oftadeh7',
                expert: 'Software Engineer',
                review: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et.',
            },
            {
                id: 8,
                image: logo,
                name: 'Mohammad Oftadeh8',
                expert: 'Software Engineer',
                review: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et.',
            },
        ]
    }

    render() {
        const settings = {
            dots: true,
            infinite: true,
            arrows: false,
            speed: 1000,
            autoplaySpeed: 5000,
            autoplay: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [{
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                }
            }]
        };
        return (
            <section className='review'>
                <h4 className='section-title'>Great Reviews</h4>
                <div className="testimonial mb-5">
                    <Slider {...settings}>
                        {
                            this.state.testimonials.map(item => (
                                <ReviewBox key={item.id} {...item} />
                            ))
                        }
                    </Slider>
                </div>
            </section>
        );
    }
}

export default Testimonial;