import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import {Link} from 'react-router-dom';
import { FaArrowRight } from 'react-icons/fa';

export default function SolutionHire() {
    const [expand, updateExpanded] = useState(false);
    return (
        <section>
            <Container className='solution-hire-content'>
                <Row className="justify-content-md-center">                
                    <Col md={4} className='solution-hire'> 
                        <h4>Want to <span>hire resources</span> to work with you?</h4>
                        <Link className='glow-on-hover btn btn-default btn-oval btn-x' as={Link} to="/contact" onClick={() => updateExpanded(false)}>Explore Hiring Services  <FaArrowRight size={12} color='bisque' /></Link>
                    </Col>
                    <Col md={4} className='solution-hire-work'> 
                        <h4>Looking for <span>UI/UX</span> & <span>Graphic Design</span> Solutions?</h4>
                        <Link className='glow-on-hover btn btn-default btn-oval btn-x' as={Link} to="/uiuxdesign" onClick={() => updateExpanded(false)}>Explore Design Services  <FaArrowRight size={12} color='bisque' /></Link>
                    </Col>
                </Row>
            </Container>
        </section>
    );

}