import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Lottie from 'react-lottie';
import dashboard from './LottieJson/dashboard-animation.json';
import working from './LottieJson/working-on-laptop.json';

export default function Uiuxanimation() {

    const dashboardanimation = {
        loop: true,
        autoplay: true,
        animationData: dashboard,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    const workinganimation = {
        loop: true,
        autoplay: true,
        animationData: working,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    return (
        <>
            <Col className='uxui-animation-col'>
                <Lottie
                    options={dashboardanimation}
                    height={350}
                    width={600}
                />
            </Col>
            <Col className='graph-animation-col'>
                <Lottie
                    options={workinganimation}
                    height={150}
                    width={270}
                />
            </Col>
        </>
    )

}