import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaArrowRight } from 'react-icons/fa';
import {Link} from 'react-router-dom';

export default function DemandSolutions() {
    const [expand, updateExpanded] = useState(false);
    return (
        <section>
            <Container className='demand-solution-content'>
                <Row className="justify-content-md-center">
                    <Col md={8} className='demand-solution'>
                        <h3>On-demand Solutions</h3>
                        <p>At MindInventory, we provide wide range of on-demand services using the most advanced technology and proficiency of our team of veteran on demand app developers. Followings are some of the trending on-demand services.</p>
                        <ul className="solution-demand-icons">
                        <li className="demand-icons">Grocery Delivery</li>
                        <li className="demand-icons">Courier & Logistics</li>
                        <li className="demand-icons">City Guide App</li>
                        <li className="demand-icons">Laundry App</li>
                        <li className="demand-icons">Car Booking</li>
                        <li className="demand-icons">News Potal</li>
                        <li className="demand-icons">Appointment</li>
                        <li className="demand-icons">Beauty & Salon</li>
                        </ul>
                        <Link className='glow-on-hover btn btn-default btn-oval btn-xl' as={Link} to="/ondemand" onClick={() => updateExpanded(false)}>Explore More  <FaArrowRight size={12} color='bisque' /></Link>
                    </Col>
                </Row>
            </Container>
        </section>
    );

}