import React, { useEffect, useRef } from 'react';
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import ContactFrom from './ContactFrom';
import SocialLists from '../SocialLists';
import Title from '../Header/Title';
import { FaSkype } from "react-icons/fa";
import { MdAddCall } from "react-icons/md";
import { MdEmail } from "react-icons/md";
import Testimonial from '../Testimonial';
import TechnologyExpert from '../Home/TechnologyExpert';
import TrendingTech from '../Home/TrendingTech';
import ChooseUs from '../Pages/ChooseUs';
import SuccessNo from '../About/SuccessNo';




export default function ContactUs() {

    

    return (
        <section>

            <Container fluid className="contact-section" id="contact">
                <Title>Contact Us</Title>
                <Container className="contact-content" >

                    <Row style={{ justifyContent: "center", padding: "10px" }}>
                        <Col md={6} className="contact-content-info">
                            <Row>                                
                                <h4 className='section-title'>Get In Touch</h4>
                                <Col md={6} className="contact-info">
                                    <h7>For work inquiry</h7>
                                    <Col className='skype'>
                                        <span><FaSkype size={32} /> Skype  <a href="hello@mesdtech" style={{ color: "white" }} target="_blank" rel="noopener noreferrer">
                                            hello@mesdtech
                                        </a></span>
                                    </Col>
                                    <Col className='email'>
                                        <span><MdEmail size={32} /> Email  <a href="#" style={{ color: "white" }} target="_blank" rel="noopener noreferrer">
                                            hello@mesdtech.com
                                        </a></span>
                                    </Col>
                                    <Col className='phone'>
                                        <span><MdAddCall size={32} /> Call  <a href="hello@mesdtech.com" style={{ color: "white" }} target="_blank" rel="noopener noreferrer">
                                            +91-983-659-0025
                                        </a></span>
                                    </Col>
                                </Col>
                                <Col md={6} className="career-info">
                                    <h7>For career inquiry</h7>
                                    <Col className='career-email'>
                                        <span><MdEmail size={32} /> Email  <a href="#" style={{ color: "white" }} target="_blank" rel="noopener noreferrer">
                                            hello@mesdtech.com
                                        </a></span>
                                    </Col>
                                    <Col className='career-phone'>
                                        <span><MdAddCall size={32} /> Call  <a href="hello@mesdtech.com" style={{ color: "white" }} target="_blank" rel="noopener noreferrer">
                                            +91-983-659-0025
                                        </a></span>
                                    </Col>
                                </Col>
                            </Row>
                            <Row>                                
                                <h4 className='section-title'>Global Presence</h4>
                                <Col md={6} className="ind-address-info">
                                    <h7>India</h7>
                                    <Col className='add-ind'>
                                        <span className='add-location d-block'>Head Office: </span>
                                    <span>Mirhat, Baidyapur, </span>
                                    <span>Burdwan 713122, </span>                                        
                                    <span>West Bengal, India.</span>
                                    <span className='d-block'>+91-983-659-0025</span>
                                    <span className='add-location d-block'>City Office: </span>
                                    <span>Baruipur, </span>
                                    <span>Kolkata - 700144, </span>                                        
                                    <span>West Bengal, India.</span>
                                    </Col>
                                </Col>
                                <Col md={6} className="usa-address-info">
                                    {/* <h7>USA</h7>
                                    <Col className='add-usa'>
                                        <span>Mirhat, Baidyapur,</span>
                                        <span>Burdwan 713122,</span>                                        
                                        <span>West Bengal, India</span>
                                        <span className='d-block'>+91-983-659-0025</span>
                                    </Col> */}
                                </Col>
                            </Row>

                        </Col>

                        <Col md={4} className="news-content-field">
                            <h4>For Project Inquiries</h4>
                            <ContactFrom />
                        </Col>
                    </Row>
                    <Row className="social">
                        <Col className='social-list'>
                        <h4 className='section-title'>Connect on Social</h4>
                            <SocialLists />
                        </Col>
                    </Row>
                </Container>
                <ChooseUs />
                <TrendingTech />
                <TechnologyExpert />
                <SuccessNo />
                <Testimonial />
            </Container>
        </section>
    );
}