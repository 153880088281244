import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Title from '../Header/Title';
import TechExpert from '../Home/TechExpert';
import ChooseUs from './ChooseUs';
import Testimonial from '../Testimonial';
import NewsLetter from '../Newsletter/NewsLetter';
import ProjectDiscuss from '../Contact/ProjectDiscuss';

export default function AngularJs() {

    return (
        <section>
            <Container fluid className="page-design-content" id="page">
                <Title>Angular Js</Title>
                <Col className='inerpage-title'>
                    <h4 className='section-title'>What we do</h4>
                </Col>
                <Row className="justify-content-md-center">
                    <Col md={8} className='page-design-title'>
                        <h4>We help businesses to build high-performance MVC capable apps using AngularJS.</h4>
                        <p>Angular is an open-source JavaScript framework that extends the funcationality of HTML. The important use of Angular is to improve browser-based applications with the help of MVC (model view controller) capability. In simpler words, it’s designed to make front-end web development and testing much easier and faster by using templates, command line tools, and IDEs. Angular offers cross platform support for today’s devices, the speed and performance required as a modern development tool, and the productivity of a framework.</p>                        
                    </Col>
                    <Col md={6} className='page-design-mid'>
                        <h5>Our High Quality Angular Development Services</h5>
                        <p>MESD Technology employs specialized Angular developers who are experienced in Javascript and jQuery to create real-time applications effortlessly in this framework. We are highly recognized as the best Angular development company for developing single page applications in an agile manner. Our team will help you to take your web or mobile app ideas from concept to reality using the Angular framework.</p>
                        <Col md={8} className='design-types'>
                            <h4>Angular web app development</h4>
                            <h4>Angular dashboards and web portals</h4>
                            <h4>Angular mobile web app development</h4>
                            <h4>Angular single-page applications</h4>
                            <h4>Angular performance optimization</h4>
                            <h4>Angular eCommerce development</h4>
                            <h4>Angular cross-platform development</h4>
                            <h4>Angular API Development</h4>
                            <h4>Migration Services</h4>                            
                        </Col>
                    </Col>
                </Row>
                <ProjectDiscuss />
                <ChooseUs />
                <TechExpert />
                <NewsLetter />
                <Testimonial />
            </Container>            
        </section>
    );

}