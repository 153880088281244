import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';


export default function ChooseUs() {

    const choosen = [
        {
            id: 1,
            name: 'Customer Satisfaction',
            content: 'We work on the latest frameworks and technologies to deliver you user-friendly, scalable, secure, and new business solutions as per your need.'
        },
        {
            id: 2,
            name: 'Dedicated Teams',
            content: 'We have a dedicated team of web app developers who are well versed in delivering high-end business solutions on several frameworks and technologies.'
        },
        {
            id: 3,
            name: 'Agile Procedure',
            content: 'We work on the Agile methodology and conduct frequent scrum events for optimizing the practices and methods and delivering the finest results.'
        },
        {
            id: 4,
            name: 'Integrity & Transparency',
            content: 'We respect your ideas and vision, provide you every small project detail regularly, and consider your worthy advice when and as needed.'
        },
        {
            id: 5,
            name: 'Flexible Engagement Models',
            content: 'Instead of working on unwanted rigid and strict parameters, we offer flexibility to our clients to select from different engagement and hiring models.'
        },
        {
            id: 6,
            name: 'Competitive Pricing',
            content: 'We offer an ideal combination of cost-effective rates with amazing quality to make sure the lowest pricing in our segment.'
        }
    ]
    return (
        <Container className="chooseoverview-content">
            <h4 className='section-title'>WHY CHOOSE US</h4>
            <Row className="justify-content-md-center">
                <Col md={8} className='chooseoverview-title'>
                    <h4>Why Choose MESD Technology for your Application</h4>
                    <p>Being a well-known web app development company, MESD Technology is recognized for serving modern business needs and delivering the best-in-class solutions at a competitive cost across the globe. Here’s why we can be the best choice when it comes to building websites and apps:</p>
                </Col>
                <Col md={8} className='chooseoverview-main'>
                    <Row>
                    {
                                choosen.map((item, i) => <Col sm={6} className="choose-architecture" key={i}>                                    
                                    <h5>{item.name}</h5>
                                    <p>{item.content}</p>
                                </Col>)
                            }
                    </Row>

                </Col>
            </Row>
        </Container>
    );
}