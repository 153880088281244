import React from "react";
import TextAnimation from "react-text-animations";

export default function TextAutomation() {

    return (
        <TextAnimation.Flip target="Web Development" text={['UI/UX Design', 'Web Development', 'App Development', 'CRM Consultant', 'AI Development']} cname="textAnimation" id="textAnimation__flip" animation={{duration:1000, delay:2000,  timingFunction:'ease-out',}} loop={true}>
            Connecting from dots to Web Development since a decade.
        </TextAnimation.Flip>
    );

}