import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Title from '../Header/Title';
import TechExpert from '../Home/TechExpert';
import ChooseUs from './ChooseUs';
import Testimonial from '../Testimonial';
import NewsLetter from '../Newsletter/NewsLetter';
import SolutionHire from '../Contact/SolutionHire';
import ProjectDiscuss from '../Contact/ProjectDiscuss';
import ProductHire from '../Contact/ProductHire';
import { FaArrowRight } from 'react-icons/fa';
import { FaCloud } from 'react-icons/fa';
import { FaPython } from 'react-icons/fa';
import { FaConnectdevelop } from 'react-icons/fa';
import { FaAllergies } from 'react-icons/fa';
import deep from '../assets/deep.png';
import robot from '../assets/robot.png';
import languge from '../assets/languge.png';
import statistics from '../assets/statistics.png';
import chart from '../assets/chart.png';
import machine from '../assets/machine.png';
import bot from '../assets/bot.png';



export default function Ai() {

    const [expand, updateExpanded] = useState(false);
    const webcontant = [
        { id: 1, name: 'Robotic Process Automation', para: 'Artificial Intelligence Platform enables you to develop digital virtual agents, chatbots, predictive agents and cognitive process automation.', link: '#', icon: robot },
        { id: 2, name: 'Natural language Processing', para: 'Natural Language Processing (NLP) based Text Analytics, Sentiment and Information Analysis Solutions for Enterprises.', link: '#', icon: languge },
        { id: 3, name: 'Customer Analytics Solutions', para: 'Customer Analytics Solutions for understanding customer behavior, enabling product recommendations and Predictive Analytics.', link: '#', icon: statistics },
        { id: 4, name: 'Enterprise operational Analytice', para: 'IT Operations Analytics solutions for operations resource planning and making better decisions about your IT environment.', link: '#', icon: chart },
        { id: 5, name: 'Deep Learing', para: 'From face recognition to object recognition that instantaneously analyse defects in industrial products, we provide AI services that integrate into your business with ease.', link: '#', icon: deep },
        { id: 6, name: 'Machine Learing', para: 'Do you have a manual process that you need to automate? Our web application development team can develop a customized, secure, and scalable system as per your business needs.', link: '#', icon: machine },
        { id: 7, name: 'Chat Bots', para: 'Understand user attitude, requirements, and emotions using AI techniques such as computational linguistics and natural language processing.', link: '#', icon: bot }
        
    ]
    return (
        <section>
            <Container className="solutionoverview-content">
                <Title>AI Solution</Title>
                <Col className='inerpage-title'>
                    <h4 className='section-title'>What we do</h4>
                </Col>
                <Row className="justify-content-md-center">
                    <Col md={5} className='solutionoverview-title'>
                        <Row>
                            <Col className='solutionover-hedding'>
                                <h4>Reimagine your business with powering AI for digital enterprise</h4>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='solutionover-para'>
                                <p>In a world powered by mobile, cloud, IoT and AI technologies, where marketplaces, D2C models, and digital products and services continually disrupt the landscape, the only constant is the huge amounts of data being generated. AI is the key to making sense of all that data, to drive intelligent actions. Enterprises need to reimagine and re-build their businesses with AI at the core, as a powerful enabler, to drive superior customer experience, improve processes and enhance decision-making while lowering time to value. We are at the cusp of a major shift – from pilots to production, from use case discovery to large scale AI-led transformation programs. The digital enterprise of the future is being reimagined today, with AI.</p>
                            </Col>
                        </Row>

                    </Col>
                    <Col md={5} className='solutionoverview-icons'>
                        <Row>
                            <Col md={6} className='solution-icon-one'>
                                <Row>
                                    <Col className='solution-enterprise-one'>
                                        <FaPython size={65} style={{ color: "white" }} />
                                        <h4>Python</h4>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='solution-enterprise-two'>
                                        <FaCloud size={65} style={{ color: "white" }} />
                                        <h4>Google cloud</h4>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={6} className='solution-icon-two'>
                                <Row>
                                    <Col className='solution-enterprise-three'>
                                        <FaConnectdevelop size={65} style={{ color: "white" }} />
                                        <h4>Machine Learning</h4>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='solution-enterprise-fore'>
                                        <FaAllergies size={65} style={{ color: "white" }} />
                                        <h4>Deep Learning</h4>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
            <Container className="page-mid-container">
                <Row className="justify-content-md-center">
                    <Col md={10} className='page-mid-title'>
                        <h4>Making AI real for enterprises</h4>
                        <p>
                        At MESD Technology, we believe that solving a business problem should lead to long-term value for enterprises. By diving into the heart of business challenges, we drive transformation in its truest sense. Right from structuring the problem, developing the transformation roadmap, deploying the most accurate solutions, and driving adoption to obtain tangible business impact…We go beyond data. BRIDGEi2i’s AI capabilities combined with AI assets and digital consulting expertise help Make AI Real for Enterprises.
                        </p>
                    </Col>
                    <Col md={10} className='page-mid-contant'>
                        <Row style={{ justifyContent: "center" }}>
                            {
                                webcontant.map((item, i) => <Col sm={4} className="page-mid-box" key={i}>
                                    <img className='page-icon-img' src={item.icon} style={{ height: "13vh" }} />
                                    <h5>{item.name}</h5>
                                    <p>{item.para}</p>
                                    <Link className='glow-on-hover btn btn-default btn-oval btn-xl' as={Link} to="/contact" onClick={() => updateExpanded(false)}>Learn More  <FaArrowRight size={12} color='bisque' /></Link>
                                </Col>)
                            }
                        </Row>
                    </Col>
                    <Col md={10}>
                        <Link className='glow-on-hover btn btn-default btn-oval btn-xl' as={Link} to="/contact" onClick={() => updateExpanded(false)}>Let's Discuss  <FaArrowRight size={12} color='bisque' /></Link>
                    </Col>
                </Row>
            </Container>
            <ProjectDiscuss />
            <TechExpert />
            <SolutionHire />
            <ChooseUs />
            <ProductHire />
            <NewsLetter />
            <Testimonial />
        </section>
    );

}