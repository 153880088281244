import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Title from '../Header/Title';
import TechExpert from '../Home/TechExpert';
import ChooseUs from './ChooseUs';
import Testimonial from '../Testimonial';
import NewsLetter from '../Newsletter/NewsLetter';
import ProjectDiscuss from '../Contact/ProjectDiscuss';
import SolutionHire from '../Contact/SolutionHire';
import ProductHire from '../Contact/ProductHire';
import TrendingTech from '../Home/TrendingTech';
import TechnologyExpert from '../Home/TechnologyExpert';

export default function DigitalTransformation() {

    return (
        <section>
            <Container fluid className="page-design-content" id="page">
                <Title>Digital Transformation</Title>
                <Col className='inerpage-title'>
                    <h4 className='section-title'>What we do</h4>
                </Col>
                <Row className="justify-content-md-center">
                    <Col md={8} className='page-design-title'>
                        <h4>Top Digital Transformation Strategist to Bank on</h4>
                        <p>Digital Transformation is the new innovative technology. The buzzword of today has emphatically transformed the facets of wide spectrum of industries globally. The transcendence of mobile apps and electronic devices with easy access to consumer data and analytics over cloud has fueled the digital disruptions further. The emergence of digital transformation services has lead to leverage the changes presenting opportunities to evolve business on new innovative ideas.</p>
                        <p>MESD Technology is a digitized first and specialist digital transformation consultant with competent experience to cater the global clientele. As the technology explores new horizons, we can be your reliable digital partner to take this online journey forward to elevated destination.</p>
                    </Col>
                    <Col md={6} className='page-design-mid'>
                        <h5>Scalable Digital Transformation Services to Create Engaging Digital Experience</h5>
                        <p>Cultivate your business higher with our comprehensive digital transformation services to lead online market from the front. Our teams of expert developers have the proficiency, platforms, and skills to deliver specialized digital business transformation services tailored to stay ahead of evolving market demands. We offer a diversified range of digital transformation solutions and services that include:</p>
                        <Col md={8} className='design-types'>
                            <h4>Digital Assessment & Strategy</h4>
                            <h4>Digital Transformation Consulting</h4>
                            <h4>Digital Innovation</h4>
                            <h4>Mobile and Web App Development</h4>
                            <h4>Digital User Experience Design</h4>
                            <h4>Analytics-led Marketing</h4>                             
                        </Col>
                    </Col>
                </Row>
                <ProjectDiscuss />
                <ChooseUs />
                <TrendingTech />
                <ProductHire />
                <TechnologyExpert />
                <NewsLetter />
                <Testimonial />
                <SolutionHire />
            </Container>            
        </section>
    );

}