import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import mission from '../assets/mission.png';
import vision from '../assets/vision.png';


export default function OverView() {

    return (
        <section>
        <Container className="overview-content">
            <h4 className='section-title'>Your Success Partner</h4>
            <Row className="justify-content-md-center">                
                <Col md={8} className='overview-text'>
                    <p>MESD Technology is a leading global provider of web applications and mobile app development and digital platform engineering services to Global Brands, Digital Publishers, Silicon Valley Entrepreneurs, Digital Agencies and SMEs as well as over 300 clients located around the world, primarily in North America, Europe, Asia, and Australia. We focus on building long-term partnerships with customers, enabling them to reimagine their businesses through a digital lens. Our industry expertise includes digital publishing, SaaS applications, online retail, financial services, consumer, software and hi-tech, business information and media, life sciences, agriculture, as well as other emerging industries.</p>
                    <p>MESD Technology helps software entrepreneurs, agencies and online brands to grow their business or, bring their ideas to life by providing them with “In-Demand Skills, On-Demand.” Digital Agencies and Software/SaaS teams hire us to scale their development teams while SMEs and Entrepreneurs choose us for outsourced product development or, for enhancement and support.</p>
                </Col>
            </Row>
            <Row style={{ justifyContent: "center", paddingBottom: "50px", paddingTop: "20px" }}>

                <Col md={4} className='our-vision'>
                <img src={vision} style={{ height: "30vh" }} />
                <h4>Our Vision</h4>
                    <p>We know that Customer’s growth is our growth, so we commit our customers to help in achieving their business goals. We believe in work with the accuracy and best quality. We want to be known as the reliable, innovative and user friendly software service provider in IT industry.</p>
                </Col>
                <Col md={4} className='our-mission'>
                <img src={mission} style={{ height: "30vh" }} />                    
                    <h4>Our Mission</h4>
                    <p>Exceed client's expectations by going beyond software to provide best Web solutions that transform data into knowledge, enabling them to solve their problems. Handover contemporary and futuristic web and mobile software solutions to the businesses ranging from startups to enterprises with affordability and satisfaction.</p>
                </Col>
            </Row>
        </Container>
        </section>
    );
}