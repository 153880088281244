import React, { useState } from 'react';
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';
import { FaArrowRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import aws from './assets/aws.svg';
import azure from './assets/azure.svg';
import digitalocean from './assets/digital-ocean.svg';
import googlecloud from './assets/google-cloud.svg';
import kubernetes from './assets/kubernetes.svg';


export default function Cloudcomputing() {

    const [expand, updateExpanded] = useState(false);
    const cloudsolution = [
        { id: 1, title: 'Amazon AWS', icon: aws },
        { id: 2, title: 'Microsoft Azure', icon: azure },
        { id: 3, title: 'Kubernetes', icon: kubernetes },
        { id: 4, title: 'Digital Ocean', icon: digitalocean },
        { id: 5, title: 'Google Cloud', icon: googlecloud }
    ]
    
    return (
        <section>
            <Container className='cloudcom-content'>
                <Row className="justify-content-md-center">
                    <Col md={12} className="cloud-com">
                        <Row>
                            <Col md={8} className='cloud-com-content'>
                                <Col md={12} className='cloud-com-title'>
                                    <Row>
                                        <Col md={7} className='cloud-title'>
                                            <h4>Cloud Computing</h4>
                                        </Col>
                                        <Col md={5} className='cloud-link'>
                                            <Link className='glow-on-hover btn btn-default btn-oval btn-x' as={Link} to="/cloud" onClick={() => updateExpanded(false)}>Explore Cloud Services</Link>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={12} className='cloud-com-service'>
                                    <Col md={12}><Col className='cloud-border'></Col></Col>
                                    <Row>
                                        <Col md={5} className='cloud-service-list'>
                                            <Col className='design-types'>
                                                <h4>Cloud App Development</h4>
                                                <h4>Cloud Configuration</h4>
                                                <h4>Cloud Integration</h4>
                                                <h4>Cloud Security</h4>
                                                <h4>Cloud Server Solutions</h4>
                                                <h4>Cloud Migration</h4>
                                            </Col>
                                        </Col>
                                        <Col md={7} className='cloud-solutions-list'>
                                            {
                                                cloudsolution.map((item, i) => <Col className="cloud-solutions" key={i}>
                                                    <img className='cloud-solutions-icon fluid' src={item.icon} style={{ height: "12vh" }} />
                                                    <h5>{item.title}</h5>
                                                </Col>)
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                            </Col>
                            <Col md={4} className='cloud-com-app'>
                                <Col md={12} className='cloud-development-main'>
                                    <Col className='iot-app-development'>
                                        <Row>
                                            <Col md={8} className='app-dev-title'>
                                                <Link as={Link} className='app-up-link' to="/iot-app-development" onClick={() => updateExpanded(false)}><h6>IOT<span className='d-block'>App</span><span className='d-block'>Development</span></h6></Link>
                                            </Col>
                                            <Col md={4} className='app-dev-link'>
                                                <Link className='btn btn-default btn-circle btn-xl' as={Link} to="/iot-app-development" onClick={() => updateExpanded(false)}><FaArrowRight size={20} color='bisque' /></Link>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col className='wearable-app-development'>
                                        <Row>
                                            <Col md={8} className='app-dev-title'>
                                                <Link as={Link} className='app-up-link' to="/wearable-app-development" onClick={() => updateExpanded(false)}><h6>Wearable<span className='d-block'>App</span><span className='d-block'>Development</span></h6></Link>
                                            </Col>
                                            <Col md={4} className='app-dev-link'>
                                                <Link className='btn btn-default btn-circle btn-xl' as={Link} to="/wearable-app-development" onClick={() => updateExpanded(false)}><FaArrowRight size={20} color='bisque' /></Link>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col className='ibeacon-app-development'>
                                        <Row>
                                            <Col md={8} className='app-dev-title'>
                                                <Link as={Link} className='app-up-link' to="/ibeacon-app-development" onClick={() => updateExpanded(false)}><h6>iBeacon<span className='d-block'>App</span><span className='d-block'>Development</span></h6></Link>
                                            </Col>
                                            <Col md={4} className='app-dev-link'>
                                                <Link className='btn btn-default btn-circle btn-xl' as={Link} to="/ibeacon-app-development" onClick={() => updateExpanded(false)}><FaArrowRight size={20} color='bisque' /></Link>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Col>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    )

}