import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Title from '../Header/Title';
import TechExpert from '../Home/TechExpert';
import ChooseUs from './ChooseUs';
import Testimonial from '../Testimonial';
import NewsLetter from '../Newsletter/NewsLetter';
import ProjectDiscuss from '../Contact/ProjectDiscuss';

export default function ReactJs() {

    return (
        <section>
            <Container fluid className="page-design-content" id="page">
                <Title>React Js</Title>
                <Col className='inerpage-title'>
                    <h4 className='section-title'>What we do</h4>
                </Col>
                <Row className="justify-content-md-center">
                    <Col md={8} className='page-design-title'>
                        <h4>We create high-performing web applications with ReactJS front-end development library.</h4>
                        <p>Hire React developers from the best web development company! We have a team of React developers that can build fast, flexible, and scalable React applications solutions to run on the web, or natively on mobile! CodeClouds has been working extensively with ReactJS to deliver top-tier solutions to our clients over the many years we’ve been building successful web and mobile applications.</p>                        
                    </Col>
                    <Col md={6} className='page-design-mid'>
                        <h5>Our High Quality React Development Services</h5>
                        <p>MESD Technology is a leading custom company providing interactive React.js development services to create modern, interactive, and reliable user interfaces across all business domains. Our Developers require extremely low supervision and each one of our Developer is proficient enough to work remotely and contribute as an active member of your development process.</p>
                        <Col md={8} className='design-types'>
                            <h4>React.js Team Augmentation</h4>
                            <h4>React.js Development</h4>
                            <h4>PWA & SPA Development</h4>
                            <h4>Testing & QA</h4>
                            <h4>Test Driven Development</h4>
                            <h4>Architecture & Design</h4>
                            <h4>Migrate to React.js</h4>
                            <h4>React API Development</h4>
                                                        
                        </Col>
                    </Col>
                </Row>
                <ProjectDiscuss />
                <ChooseUs />
                <TechExpert />
                <NewsLetter />
                <Testimonial />
            </Container>            
        </section>
    );

}