import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import {Link} from 'react-router-dom';
import { MdBusiness } from 'react-icons/md';
import { MdHouseboat } from 'react-icons/md';
import { MdChair } from 'react-icons/md';
import { FaFortAwesome } from 'react-icons/fa';
import {FaArrowRight} from 'react-icons/fa';
import Clouds from './assets/clouds.png';
import Devops from './assets/devops.png';
import Digiid from './assets/digi-id.png';
import Engineering from './assets/engineering.png';
import Responsive from './assets/responsive.png';
import Tiedhands from './assets/tied-hands.png';

export default function SolutionOverView() {
    const [expand, updateExpanded] = useState(false);
    const architecture = [
        { id: 1, name: 'Enterprise mobility Solutions', link: '/enterprise-mobility-solution', icon: Responsive },
        { id: 2, name: 'Cloud Computing Solutions', link: '/cloud', icon: Clouds },
        { id: 3, name: 'Artificial Intelligence Services', link: '/ai', icon: Devops },
        { id: 4, name: 'Software Solutions for Startup', link: '/software-solution', icon: Tiedhands },
        { id: 5, name: 'Product Engineering Services', link: '/product-engineering', icon: Engineering },
        { id: 6, name: 'Digital transformation Serveces', link: '/digital-transformation', icon: Digiid }
    ]
    return (
        <section>
            <Container className="solutionoverview-content">
                <h4 className='section-title'>What we do</h4>
                <Row className="justify-content-md-center">
                    <Col md={5} className='solutionoverview-title'>
                        <Row>
                            <Col className='solutionover-hedding'>
                                <h4>We design and develop user centric SaaS products, web app and mobile applications since a decade.</h4>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='solutionover-para'>
                                <p>We work with diverse brands, organizations, enterprises, startups and individuals to create powerful softwares from excellent idea.</p>
                            </Col>
                        </Row>

                    </Col>
                    <Col md={5} className='solutionoverview-icons'>
                        <Row>
                            <Col md={6} className='solution-icon-one'>
                                <Row>
                                    <Col className='solution-enterprise-one'>
                                        <FaFortAwesome size={65} style={{ color: "white" }} />
                                        <h4>Enterprise</h4>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='solution-enterprise-two'>
                                        <MdBusiness size={65} style={{ color: "white" }} />
                                        <h4>Organizations</h4>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={6} className='solution-icon-two'>
                                <Row>
                                    <Col className='solution-enterprise-three'>
                                        <MdHouseboat size={65} style={{ color: "white" }} />
                                        <h4>Startups</h4>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='solution-enterprise-fore'>
                                        <MdChair size={65} style={{ color: "white" }} />
                                        <h4>Individuals</h4>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row style={{ paddingBottom: "50px", paddingTop: "50px" }}>
                    <Col className='architecture-content'>
                        <Row style={{justifyContent: "center"}}>
                            {
                                architecture.map((item, i) => <Col sm={3} className="solution-architecture" key={i}>
                                    <img className='icon-img' src={item.icon} style={{ height: "15vh" }} />
                                    <h5>{item.name}</h5>
                                    <Link className='btn btn-default btn-circle btn-xl' as={Link} to={item.link} onClick={() => updateExpanded(false)}><FaArrowRight size={20} color='bisque' /></Link>
                                </Col>)
                            }
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}