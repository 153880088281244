import React from 'react';
import { Container } from 'react-bootstrap';
import Title from '../Header/Title';
import UIUXDesign from './UIUXDesign';
import Webdevelopment from './Webdevelopment';
import Mobileapp from './Mobileapp';
import Cloudcomputing from './Cloudcomputing';
import ProjectDiscuss from '../Contact/ProjectDiscuss';
import TrendingTech from '../Home/TrendingTech';
import ChooseUs from '../Pages/ChooseUs';
import SolutionHire from '../Contact/SolutionHire';
import NewsLetter from '../Newsletter/NewsLetter';
import Testimonial from '../Testimonial';
import TechnologyExpert from '../Home/TechnologyExpert';



export default function Service() {

    return (
        <section>
            <Container fluid className="service-section" id="service">
                <Title>Our Services</Title>
                <UIUXDesign />
                <Webdevelopment />
                <Mobileapp />
                <Cloudcomputing />
                <ProjectDiscuss />
                <TrendingTech />
                <ChooseUs />
                <SolutionHire />
                <TechnologyExpert />
                <NewsLetter />
                <Testimonial />
            </Container>
        </section>
    );


}