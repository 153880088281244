import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Title from '../Header/Title';
import TechExpert from '../Home/TechExpert';
import ChooseUs from './ChooseUs';
import Testimonial from '../Testimonial';
import NewsLetter from '../Newsletter/NewsLetter';
import { FaArrowRight } from 'react-icons/fa';
import { FaFigma } from 'react-icons/fa';
import { FaSketch } from 'react-icons/fa';
import { SiAdobeaftereffects } from 'react-icons/si';
import { SiAdobephotoshop } from 'react-icons/si';
import webapplication from '../assets/webapplication.png';
import uiux from '../assets/uiux.png';
import landpage from '../assets/landpage.png';
import socialad from '../assets/socialad.png';
import identity from '../assets/identity.png';
import flyers from '../assets/flyers.png';
import custom from '../assets/custom.png';
import videoanimation from '../assets/videoanimation.png';
import sicialicon from '../assets/sicialicon.png';



export default function Uiuxdesign() {

    const [expand, updateExpanded] = useState(false);
    const webcontant = [
        { id: 1, name: 'Web Design', para: 'Want custom layouts for your website? We design them in informative, engaging ways. This includes web mockups, visualizations, and consultations before we get started on your site, so you’re part of the process.', link: '#', icon: webapplication },
        { id: 2, name: 'UX/UI Design', para: 'We will work with your team to develop beautiful design mockups highlighting your ideal goals, keeping in mind best practices and mobile-first implementation.', link: '#', icon: uiux },
        { id: 3, name: 'Landing Page Design', para: 'Marketing, Digital, and Product teams rely on Superside to create landing pages from scratch or based on existing materials. We deliver thoughtful UX wireframes and high-fidelity UI designs.', link: '#', icon: landpage },
        { id: 4, name: 'Social Ads', para: 'Need an ad campaign to look amazing? We’ll design it so it grabs attention and pulls the eye toward your message. Set yourself apart from your competition with modern and stylish visuals.', link: '#', icon: socialad },
        { id: 5, name: 'Brand Identity Design', para: 'Classic. Timeless. Effective. Your corporate identity is the most basic reflection of your business. Therefore, make yours excellent. We offer a variety of looks, finishes, and papers, so you can have them anyway you like.', link: '#', icon: identity },
        { id: 6, name: 'Flyers and Brochures', para: 'Print is making a comeback. You may have all your information online, but don’t underestimate the power of a simple brochure. You can leave them in physical places, give them to customers, or distribute them to your sales team. We design all types of brochures in full color.', link: '#', icon: flyers },
        { id: 7, name: 'Social Media Graphics', para: 'Our designers can help you create professionally designed layouts for Facebook, LinkedIn, Twitter, Pinterest, Instagram, and more. We can help you with everything from photos and images, videos, stories, cover art, infographics, quotes, blog images, GIFs, lists, screenshots with arrows, charts, thumbnails, posters and logos.', link: '#', icon: sicialicon },
        { id: 8, name: 'Animated Videos', para: 'Boost your video marketing with custom-designed animated videos. We can help your create bespoke animated videos, explainer videos like Whiteboard Animation, Motion Graphics, Motion Graphics, and Character animation. Each video is unique and helps you establish your brand in the crowded marketplace.', link: '#', icon: videoanimation },
        { id: 9, name: 'Custom Graphic Design', para: 'Our creative designers can help you with just about any sort of design needs includes infographics, 2d characters, calendar, photo collage, posters, cards etc. We employ some of the best creative talent from the top design schools in India. With our services you and truly differentiate your brand without extravagant costs.', link: '#', icon: custom }
    ]
    return (
        <section>
            <Container className="solutionoverview-content">
                <Title>UI/UX Design</Title>
                <Col className='inerpage-title'>
                    <h4 className='section-title'>What we do</h4>
                </Col>
                <Row className="justify-content-md-center">
                    <Col md={5} className='solutionoverview-title'>
                        <Row>
                            <Col className='solutionover-hedding'>
                                <h4>We Design Visually Stunning & User-Friendly Customer Experience.</h4>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='solutionover-para'>
                                <p>The Design division at MESD Technology works as an independent wing with a dedicated team of User interface(UI) Designers, User Experience (UX) Designers, Graphic Designers and Animators.</p>
                            </Col>
                        </Row>

                    </Col>
                    <Col md={5} className='solutionoverview-icons'>
                        <Row>
                            <Col md={6} className='solution-icon-one'>
                                <Row>
                                    <Col className='solution-enterprise-one'>
                                        <FaFigma size={65} style={{ color: "white" }} />
                                        <h4>Figma</h4>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='solution-enterprise-two'>
                                        <FaSketch size={65} style={{ color: "white" }} />
                                        <h4>Sketch</h4>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={6} className='solution-icon-two'>
                                <Row>
                                    <Col className='solution-enterprise-three'>
                                        <SiAdobeaftereffects size={65} style={{ color: "white" }} />
                                        <h4>After Effects</h4>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='solution-enterprise-fore'>
                                        <SiAdobephotoshop size={65} style={{ color: "white" }} />
                                        <h4>Photoshop</h4>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
            <Container className="page-mid-container">
                <Row className="justify-content-md-center">
                    <Col md={10} className='page-mid-title'>
                        <h4>Grow and Elevate Your Brand with Dedicated Designer</h4>
                        <p>
                        You’ve heard the saying “A picture is worth a thousand words?” Well, you can say a lot with stunning, interesting, and creative graphic design. And, it’s effective: as the first thing your customers or prospects will see, good graphic design will guide the eye toward your content. When you partner with our design team, you’ll get fresh and creative visuals not only to attract new customers but also to help elevate your brand’s message. We produce designs for print, social, mobile, and web, so when we’re done, you’ll have piles of gorgeous work across all your channels, making you a standout in your field.
                        </p>
                    </Col>
                    <Col md={10} className='page-mid-contant'>
                        <Row style={{ justifyContent: "center" }}>
                            {
                                webcontant.map((item, i) => <Col sm={4} className="page-mid-box" key={i}>
                                    <img className='page-icon-img' src={item.icon} style={{ height: "13vh" }} />
                                    <h5>{item.name}</h5>
                                    <p>{item.para}</p>
                                    <Link className='glow-on-hover btn btn-default btn-oval btn-xl' as={Link} to="/contact" onClick={() => updateExpanded(false)}>Learn More  <FaArrowRight size={12} color='bisque' /></Link>
                                </Col>)
                            }
                        </Row>
                    </Col>
                    <Col md={10}>
                        <Link className='glow-on-hover btn btn-default btn-oval btn-xl' as={Link} to="/contact" onClick={() => updateExpanded(false)}>Let's Discuss  <FaArrowRight size={12} color='bisque' /></Link>
                    </Col>
                </Row>
            </Container>
            <TechExpert />
            <ChooseUs />
            <NewsLetter />
            <Testimonial />
        </section>
    );

}