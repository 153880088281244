import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Title from '../Header/Title';
import TechExpert from '../Home/TechExpert';
import ChooseUs from './ChooseUs';
import Testimonial from '../Testimonial';
import NewsLetter from '../Newsletter/NewsLetter';
import ProjectDiscuss from '../Contact/ProjectDiscuss';

export default function Fintech() {

    return (
        <section>
            <Container fluid className="page-design-content" id="page">
                <Title>Fintech</Title>
                <Col className='inerpage-title'>
                    <h4 className='section-title'>What we do</h4>
                </Col>
                <Row className="justify-content-md-center">
                    <Col md={8} className='page-design-title'>
                        <h4>Custom Financial Software Development Company</h4>
                        <p>From P2P money transfers to digital lending, our custom Fintech solutions have opened up new possibilities for consumers today. With our suite of digital services, we've transformed the way people bank, pay, and invest in this contactless world.</p>
                        <p>MESD Technology is a one-stop digital specialist hub that offers an array of digital first banking and financial solutions within budget-friendly prices. The introduction of Mobile Wallet App has been a major breakthrough for the financial industry bringing a sea-change in the business-consumer digital relationship.</p>
                    </Col>
                    <Col md={6} className='page-design-mid'>
                        <h5>Financial Software Development Services</h5>
                        <p>When Fintech companies approach us, we envision their dreams and fulfill them with a custom approach. Many who earlier held onto clunky processes now witness significant growth - thanks to our tailored financial software solutions. We digitized many run-of-the-mill tasks like automating investments, paying bills, or applying for loans. Our solutions have delivered results twice as good as our competitors, that too, at 50% less cost. Moreover, our services have helped people bypass age-old banking and go fully digital.</p>
                        <Col md={8} className='design-types'>
                            <h4>Fintech Mobile and Web Apps</h4>
                            <h4>Fintech UI/UX</h4>
                            <h4>Staff Augmentation</h4>
                            <h4>FinTech Payment Solutions</h4>
                            <h4>POS Solutions</h4>                            
                        </Col>
                    </Col>
                </Row>
                <ProjectDiscuss />
                <ChooseUs />
                <TechExpert />
                <NewsLetter />
                <Testimonial />
            </Container>            
        </section>
    );

}