import React, { useEffect, useRef, useState } from 'react';
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import ContactFrom from '../Contact/ContactFrom';
import animationData from './LottieJson/talkabout.json';
import Lottie from 'react-lottie';
import { FaSkype } from "react-icons/fa";
import { MdAddCall } from "react-icons/md";
import { MdEmail } from "react-icons/md";


export default function NewsLetter() {
    
    const newsanimation = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    

    return (
        <section>

            <Container fluid className="news-section" id="news">

                <Container className="news-content" >
                    <h4 className='section-title'>Get in touch</h4>
                    <Row style={{ justifyContent: "center", padding: "10px" }}>
                        <Col md={4} className="news-content-title">
                            <div className='newsanimation'>
                                <Lottie
                                    options={newsanimation}
                                    height={330}
                                    width={400}
                                />
                            </div>
                            <div className='news-contact-info'>
                                <Col className='skype'>
                                    <span><FaSkype size={32} /> Skype  <a href="hello@mesdtech" style={{ color: "white" }} target="_blank" rel="noopener noreferrer">
                                        hello@mesdtech
                                    </a></span>
                                </Col>
                                <Col className='email'>
                                    <span><MdEmail size={32} /> Email  <a href="#" style={{ color: "white" }} target="_blank" rel="noopener noreferrer">
                                        hello@mesdtech.com
                                    </a></span>
                                </Col>
                                <Col className='phone'>
                                    <span><MdAddCall size={32} /> Call  <a href="hello@mesdtech.com" style={{ color: "white" }} target="_blank" rel="noopener noreferrer">
                                        +91-983-659-0025
                                    </a></span>
                                </Col>
                            </div>
                        </Col>

                        <Col md={4} className="news-content-field">
                            <h4>For Project Inquiries</h4>
                            <ContactFrom />
                        </Col>
                    </Row>
                </Container>
            </Container>
        </section>
    );
}