import React, { useState } from "react";
import { Navbar, Nav, Container, Button } from "react-bootstrap";
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link, useHref } from "react-router-dom";
import logo from "./logo_mesd.png";


export default function NavBar() {
    const [expand, updateExpanded] = useState(false);
    const [navColour, updateNavbar] = useState(false);

    function scrollHandler() {
        if (window.scrollY >= 20) {
            updateNavbar(true);
        } else {
            updateNavbar(false);
        }
    }

    window.addEventListener("scroll", scrollHandler);


    return (

        <Navbar expanded={expand} fixed="top" expand="md" className={navColour ? "sticky" : "navbar"} >
            <Container>
                <Navbar.Brand href="/">
                    <img src={logo} className="img-fluid logo" alt="brand" />
                </Navbar.Brand>
                <Navbar.Toggle
                    aria-controls="responsive-navbar-nav"
                    onClick={() => {
                        updateExpanded(expand ? false : "expanded");
                    }}
                >
                    <span></span>
                    <span></span>
                    <span></span>
                </Navbar.Toggle>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="ml-auto" defaultActiveKey="#home">
                        <Nav.Item>
                            <Nav.Link as={Link} to="/" onClick={() => updateExpanded(false)}>
                                Home
                            </Nav.Link>

                        </Nav.Item>

                        <Nav.Item>
                            <Nav.Link
                                as={Link}
                                to="/about"
                                onClick={() => updateExpanded(false)}
                            >
                                About
                            </Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                            <NavDropdown title="Services" id="nav-services" alignLeft>
                                <NavDropdown.Item as={Link} to="/services" onClick={() => updateExpanded(false)}>Our All Services</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/uiuxdesign" onClick={() => updateExpanded(false)}>UI/UX Design</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/uiuxdesign" onClick={() => updateExpanded(false)}>Graphic Design</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/webapplication" onClick={() => updateExpanded(false)}>Web Application</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/mobileapp" onClick={() => updateExpanded(false)}>Mobile App</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/iot-app-development" onClick={() => updateExpanded(false)}>IOT App Development</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/wearable-app-development" onClick={() => updateExpanded(false)}>Wearable App Development</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/ibeacon-app-development" onClick={() => updateExpanded(false)}>iBeacon App Development</NavDropdown.Item>
                            </NavDropdown>
                        </Nav.Item>

                        <Nav.Item>
                            <NavDropdown title="Solutions" id="nav-solutions" alignLeft>
                                <NavDropdown.Item as={Link} to="/solutions" onClick={() => updateExpanded(false)}>Our All Solutions</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/crm" onClick={() => updateExpanded(false)}>CRM Solutions</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/enterprise-mobility-solution" onClick={() => updateExpanded(false)}>Enterprise mobility Solutions</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/cloud" onClick={() => updateExpanded(false)}>Cloud Computing Solutions</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/ai" onClick={() => updateExpanded(false)}>Artificial Intelligence</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/software-solution" onClick={() => updateExpanded(false)}>Software Solutions for Startup</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/product-engineering" onClick={() => updateExpanded(false)}>Product Engineering</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/digital-transformation" onClick={() => updateExpanded(false)}>Digital transformation</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/software-solution" onClick={() => updateExpanded(false)}>On-demand Solutions</NavDropdown.Item>
                            </NavDropdown>
                        </Nav.Item>

                        <Nav.Item className="infobtn menu-item">
                            <Nav.Link
                                as={Link}
                                to="/contact"
                                onClick={() => updateExpanded(false)}
                            >
                                Contact Us
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}