import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Title from '../Header/Title';
import TechExpert from '../Home/TechExpert';
import ChooseUs from './ChooseUs';
import Testimonial from '../Testimonial';
import NewsLetter from '../Newsletter/NewsLetter';
import ProjectDiscuss from '../Contact/ProjectDiscuss';

export default function ReactNativeApp() {

    return (
        <section>
            <Container fluid className="page-design-content" id="page">
                <Title>React Native App</Title>
                <Col className='inerpage-title'>
                    <h4 className='section-title'>What we do</h4>
                </Col>
                <Row className="justify-content-md-center">
                    <Col md={8} className='page-design-title'>
                        <h4>Stunning & high functionality app for your business with react native app development.</h4>
                        <p>MESD Technology is a prominent React Native Development Company having adeptness with most recent web and mobile technology. We believe in providing next-gen React Native app development services with a clear insight into your business needs and offer unmatched solutions to create robust mobile experiences.</p>                        
                    </Col>
                    <Col md={6} className='page-design-mid'>
                        <h5>Leverage the Power of React Native in All Stages of App Development</h5>
                        <p>We are one of the industry-best React Native app development companies that provide goal-oriented, result-driven, and tailor-made mobile solutions to thousands of clients globally. Choose our best-in-class React Native development services to get faster and seamless cross-platform mobile development solutions that are rich in performance, scalability, and technology.</p>
                        <Col md={8} className='design-types'>
                            <h4>Custom Mobile App Development</h4>
                            <h4>React Native Migration</h4>
                            <h4>React Native Consulting</h4>
                            <h4>Mobile App Backend & API Development</h4>
                            <h4>Testing and QA Solutions</h4>
                            <h4>React Native Development for iOS and Android</h4>
                            <h4>React Native Cross-Platform Development</h4>                                                       
                        </Col>
                    </Col>
                </Row>
                <ProjectDiscuss />
                <ChooseUs />
                <TechExpert />
                <NewsLetter />
                <Testimonial />
            </Container>            
        </section>
    );

}