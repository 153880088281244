import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Title from '../Header/Title';
import TechExpert from '../Home/TechExpert';
import ChooseUs from './ChooseUs';
import Testimonial from '../Testimonial';
import NewsLetter from '../Newsletter/NewsLetter';
import ProjectDiscuss from '../Contact/ProjectDiscuss';
import SolutionHire from '../Contact/SolutionHire';
import ProductHire from '../Contact/ProductHire';

export default function WearableApp() {

    return (
        <section>
            <Container fluid className="page-design-content" id="page">
                <Title>Wearable App</Title>
                <Col className='inerpage-title'>
                    <h4 className='section-title'>What we do</h4>
                </Col>
                <Row className="justify-content-md-center">
                    <Col md={8} className='page-design-title'>
                        <h4>We make your workplace smarter and efficient using wearable devices app development.</h4>
                        <p>we have proficiency to deliver custom wearable app development solutions for different industries such as sports, health and fitness, gaming and several others. Our professional wearable app developers are capable to develop hi-end apps such as health and fitness tracking app for Apple Watch, Android Wear or a Google glass.</p>
                        <p>Our wearable apps give several benefits to small and big enterprises as well as manufacturing units to enhance their work processes. It provides real time interaction between the management and employees at work.</p>
                    </Col>
                    <Col md={6} className='page-design-mid'>
                        <h5>Next-Gen Wearable App Development Services to Streamline Your Business</h5>
                        <p>As an experienced wearable app development company, we have know-how to offer best IoT solutions and wearable apps precisely suits your business needs. It runs efficiently and seamlessly on advanced wearable devices such as Apple Watch, Android wear, Google glasses, etc. We proffer comprehensive wearable devices app development services for different industries.</p>
                        <Col md={8} className='design-types'>
                            <h4>Custom Wearable App Development</h4>
                            <h4>Smart Watch App Development</h4>
                            <h4>Fitness Tracking App Development</h4>
                            <h4>Wearable App UI/UX Design</h4>
                            <h4>IoT Based Wearable App Development</h4>                            
                        </Col>
                    </Col>
                </Row>
                <ProductHire />
                <ChooseUs />
                <TechExpert />
                <SolutionHire />
                <NewsLetter />
                <Testimonial />
                <ProjectDiscuss />
            </Container>            
        </section>
    );

}