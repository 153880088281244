import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from "react-bootstrap";
import SocialLists from "../SocialLists";
import { IconContext } from "react-icons";
import { FaHeart } from "react-icons/fa";
import { FaPhone } from 'react-icons/fa';
import { FaEnvelope } from 'react-icons/fa';
import { FaHandHoldingHeart } from 'react-icons/fa';
import { FaStar } from 'react-icons/fa';
import { FaEllipsisH } from 'react-icons/fa';
import { FaFacebookF } from 'react-icons/fa';
import { FaInstagram } from 'react-icons/fa';
import { FaLinkedinIn } from 'react-icons/fa';
import { FaTwitter } from 'react-icons/fa';
import { FaYoutube } from 'react-icons/fa';
import { FaEllipsisV } from 'react-icons/fa';



function Footer() {
    let date = new Date();
    let year = date.getFullYear();
    const [expand, updateExpanded] = useState(false);

    return (
        <Container fluid className="footer" id="footer">
            <Row>
                <Col md={4} className="footercont">
                    <ul className="nav flex-column">
                        <li className="footer-item footer-one">
                            <span className='footer-title'>Product</span>
                        </li>
                        <li className="footer-item">
                            <Link className='footer-link' as={Link} to="/uiuxdesign" onClick={() => updateExpanded(false)}>UI/UX Design</Link>
                        </li>
                        <li className="footer-item">
                            <Link className='footer-link' as={Link} to="/uiuxdesign" onClick={() => updateExpanded(false)}>Graphic Design</Link>
                        </li>
                        <li className="footer-item">
                            <Link className='footer-link' as={Link} to="/cloud" onClick={() => updateExpanded(false)}>Cloud Computing</Link>
                        </li>
                        <li className="footer-item">
                            <Link className='footer-link' as={Link} to="/webapplication" onClick={() => updateExpanded(false)}>Web Application</Link>
                        </li>
                        <li className="footer-item">
                            <Link className='footer-link' as={Link} to="/mobileapp" onClick={() => updateExpanded(false)}>Mobile Application</Link>
                        </li>
                        <li className="footer-item">
                            <Link className='footer-link' as={Link} to="/fintech" onClick={() => updateExpanded(false)}>Fintech</Link>
                        </li>
                        <li className="footer-item">
                            <Link className='footer-link' as={Link} to="/e-commerce" onClick={() => updateExpanded(false)}>E-commerce & Retail</Link>
                        </li>
                        <li className="footer-item">
                            <Link className='footer-link' as={Link} to="/agricultural" onClick={() => updateExpanded(false)}>Agricultural</Link>
                        </li>
                        <li className="footer-item">
                            <Link className='footer-link' as={Link} to="/fitness" onClick={() => updateExpanded(false)}>Fitness</Link>
                        </li>
                        <li className="footer-item">
                            <Link className='footer-link' as={Link} to="/food-delivery" onClick={() => updateExpanded(false)}>Food Delivery</Link>
                        </li>
                        <li className="footer-item">
                            <Link className='footer-link' as={Link} to="/media-entertainment" onClick={() => updateExpanded(false)}>Media & Entertainment</Link>
                        </li>
                        <li className="footer-item">
                            <Link className='footer-link' as={Link} to="/education" onClick={() => updateExpanded(false)}>Education & E-Learning</Link>
                        </li>
                        <li className="footer-item">
                            <Link className='footer-link' as={Link} to="/business" onClick={() => updateExpanded(false)}>Business</Link>
                        </li>
                        <li className="footer-item">
                            <Link className='footer-link' as={Link} to="/social-networking" onClick={() => updateExpanded(false)}>Social Networking</Link>
                        </li>
                    </ul>
                </Col>
                <Col md={4} className="footercont">
                    <ul className="nav flex-column">
                        <li className="footer-item footer-one">
                            <span className="footer-title">Resource</span>
                        </li>
                        <li className="footer-item">
                            <Link className='footer-link' as={Link} to="/about" onClick={() => updateExpanded(false)}>About us</Link>
                        </li>
                        <li className="footer-item">
                            <Link className='footer-link' as={Link} to="/services" onClick={() => updateExpanded(false)}>Services</Link>
                        </li>
                        <li className="footer-item">
                            <Link className='footer-link' as={Link} to="/solutions" onClick={() => updateExpanded(false)}>Solutions</Link>
                        </li>                        
                        <li className="footer-item">
                            <Link className='footer-link' as={Link} to="/contact" onClick={() => updateExpanded(false)}>Career</Link>
                        </li>
                        <li className="footer-item">
                            <Link className='footer-link' as={Link} to="/contact" onClick={() => updateExpanded(false)}>Portfolio</Link>
                        </li>
                        <li className="footer-item">
                            <Link className='footer-link' as={Link} to="/contact" onClick={() => updateExpanded(false)}>Our Brand</Link>
                        </li>
                        <li className="footer-item">
                            <Link className='footer-link' as={Link} to="/contact" onClick={() => updateExpanded(false)}>Partner Ecosystem</Link>
                        </li>
                    </ul>
                </Col>
                <Col md={4} className="footercont">
                    <ul className="nav flex-column">
                        <li className="footer-item footer-one">
                            <span className="footer-title">Contact & Support</span>
                        </li>
                        <li className="footer-item">
                            <span className="footer-link"><FaPhone /> +91 983 659 0025</span>
                        </li>
                        <li className="footer-item">
                            <span className="footer-link"><FaEnvelope /> hello@mesdtech.com</span>
                        </li>
                        <li className="footer-item">
                            <Link className='footer-link' as={Link} to="/contact" onClick={() => updateExpanded(false)}><FaHandHoldingHeart /> Contact Us</Link>
                        </li>
                        <li className="footer-item">
                            <Link className='footer-link' as={Link} to="/contact" onClick={() => updateExpanded(false)}><FaStar /> Give feedback</Link>
                        </li>
                    </ul>
                </Col>
            </Row>
            <Col class="text-center">
                <IconContext.Provider value={{ className: "footersocial-icons" }}>
                    <FaEllipsisH /><FaEllipsisH />
                </IconContext.Provider>
                <Col className="footersocial justify-content-md-center">
                    <ul className="list-inline social-buttons">                        
                        <li className="list-inline-item icon-item">
                            <a href="https://www.facebook.com/mesdtechnology" target="_blank"><FaFacebookF size={20} /></a>
                        </li>
                        <li className="list-inline-item icon-item">
                            <a href="https://www.instagram.com/mesdtech/" target="_blank"><FaInstagram size={20} /></a>
                        </li>
                        <li className="list-inline-item icon-item">
                            <a href="https://twitter.com/mesdtech" target="_blank"><FaTwitter size={20} /></a>
                        </li>
                        <li className="list-inline-item icon-item">
                            <a href="https://www.linkedin.com/company/mesdtech/" target="_blank"><FaLinkedinIn size={20} /></a>
                        </li>
                        <li className="list-inline-item icon-item">
                            <a href="https://www.youtube.com/channel/UCLjhK6XvHyElYwSJ-cDnFMg" target="_blank"><FaYoutube size={20} /></a>
                        </li>                       
                    </ul>
                </Col>
            </Col>
            <Col>
                <Row className="footercopy">
                    <Col md={4} className="footer-copywright">
                        <span className="text-copy">Copyright © {year} <a href="#" style={{ color: "white" }} target="_blank" rel="noopener noreferrer">The MESD Technology</a></span>
                    </Col>
                    <Col md={4} className="footer-copywright">
                        <span className="text-copy">Made with <a href="https://www.facebook.com/" style={{ color: "red" }} target="_blank" rel="noopener noreferrer"><FaHeart /></a> at The MESD Technology</span>
                    </Col>
                    <Col md={4} className="footer-body">
                        <ul className="list-inline quick-links">
                            <li className="list-inline-item">
                                <a href="#">Privacy Policy</a>
                            </li>
                            <li className="list-inline-item">
                                <a href="#">Terms of Use</a>
                            </li>
                        </ul>
                    </Col>
                </Row>
            </Col>
        </Container>
    );
}
export default Footer;