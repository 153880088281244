import React, { useState, useEffect } from "react";
import TypeAnimation from 'react-type-animation';
import { Container, Row, Col } from 'react-bootstrap';


export default function TextType() {

    return (
        <Col className='testtypewrite'>
            <TypeAnimation
                cursor={true}
                sequence={[
                    'Pre-define width',
                   2000,
                    'To prevent layout.',
                    2000,
                ]}
                wrapper="h2"
                className="o-hero-title"
                repeat={Infinity}
            />
        </Col>
    );

}