import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import TextAnimation from './TextAutomation';
import TextType from './TextType';
import { render } from "react-dom";
import HeroAnimation from './HeroAnimation';
import TopBar from './TopBar';



export default function HeroSection() {

   
    return (
        <section>
            <Container className='hero-solution-content'>
                <Row className="justify-content-md-center">
                    <TopBar />
                    <Col md={12} className='hero-solution-main'>
                        <Row>                            
                            <Col md={12} className='hero-solution'>
                                <Row>
                                    <Col md={6} className="hero-text-animation">
                                        <Row className="justify-content-md-center">
                                            <Col className='hero-text justify-content-md-center'>
                                                <TextType />
                                                <TextAnimation />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={6} className="hero-animation">
                                        <HeroAnimation />                                        
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );

}