import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Title from '../Header/Title';
import TechExpert from '../Home/TechExpert';
import ChooseUs from './ChooseUs';
import Testimonial from '../Testimonial';
import NewsLetter from '../Newsletter/NewsLetter';
import ProjectDiscuss from '../Contact/ProjectDiscuss';
import ProductHire from '../Contact/ProductHire';
import TrendingTech from '../Home/TrendingTech';
import TechnologyExpert from '../Home/TechnologyExpert';
import SolutionHire from '../Contact/SolutionHire';

export default function SoftwareSolution() {

    return (
        <section>
            <Container fluid className="page-design-content" id="page">
                <Title>Software Solution</Title>
                <Col className='inerpage-title'>
                    <h4 className='section-title'>What we do</h4>
                </Col>
                <Row className="justify-content-md-center">
                    <Col md={8} className='page-design-title'>
                        <h4>Rocket Fuel Your Startup With Our Top-Class App Development Solutions</h4>
                        <p>We are an adept digital startup app and web development company with a knack for futuristic software technology that adds wings to new venture to fly it high.</p>
                        <p>MESD Technology is the right digital junction helping Startups, New Ventures and Small Businesses with comprehensive startup solutions at the most cost-effective prices. We help startups nurture their idea with the right set of tailor-made strategy and provide the launch pad for a successful flight.</p>
                    </Col>
                    <Col md={6} className='page-design-mid'>
                        <h5>Robust and Scalable App Development Services for Startups</h5>
                        <p>With our outstanding expertise in diverse app development tools, we can help startups to do big things. Whether you are looking for a local solution or one over the cloud, our expert developers can always deliver it within your budget and project timeline. We offer diverse startup app development services that include:</p>
                        <Col md={8} className='design-types'>
                            <h4>Custom mobile app development</h4>
                            <h4>Cross-platform app development</h4>
                            <h4>Web app development</h4>
                            <h4>Consulting and prototyping</h4>
                            <h4>Automated QA services</h4>                            
                        </Col>
                    </Col>
                </Row>
                <ProjectDiscuss />
                <ChooseUs />
                <TechnologyExpert />
                <TrendingTech />
                <ProductHire />
                <NewsLetter />
                <Testimonial />
                <SolutionHire />
            </Container>            
        </section>
    );

}