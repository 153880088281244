import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import success from '../assets/success.png';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import Slide from 'react-reveal/Slide';



export default function SuccessNo() {
    const [focus, setFocus] = React.useState(false);
    const [isVisible, setVisible] = React.useState(false);
    return (
        <section>
            <Container className="success-content">
                <h4 className='section-title'>Success In Numberss</h4>
                <Row>
                    <Col sm={4} className='success-img'>
                        <img src={success} style={{ height: "30vh" }} />
                    </Col>
                    <Col sm={8} className='success-counter'>
                        <Row>
                            <Slide left>
                                <Col className='success-counts1'>
                                    <CountUp start={focus ? 2000 : null} end={2100} duration={3} redraw={true} suffix='+'>
                                        {({ countUpRef }) => (
                                            <h4>
                                                <span ref={countUpRef} />
                                                <VisibilitySensor
                                                    onChange={isVisible => {
                                                        if (isVisible) {
                                                            setFocus(true);
                                                        }
                                                    }}
                                                >
                                                </VisibilitySensor>
                                            </h4>
                                        )}
                                    </CountUp>
                                    <p>Tasks Completed</p>
                                </Col>
                            </Slide>
                            </Row>
                            <Row>
                            <VisibilitySensor onChange={isVisible => {
                                                        if (isVisible) {
                                                            setFocus(true);
                                                        }
                                                    }}
                                                >                                                
                            <Col className='success-counts2'>                                
                                    <CountUp start={focus ? 0 : null} end={500} duration={4} redraw={true} suffix='+'>
                                        {({ countUpRef }) => (
                                            <h4>
                                                <span ref={countUpRef} />
                                                
                                            </h4>
                                        )}
                                    </CountUp>
                                    <p>Projects Completed</p>                               
                            </Col>
                            </VisibilitySensor>
                            <VisibilitySensor onChange={isVisible => {
                                                        if (isVisible) {
                                                            setFocus(true);
                                                        }
                                                    }}
                                                >                                                
                            <Col className='success-counts3'>                                
                                    <CountUp start={focus ? 0 : null} end={100} duration={5} redraw={true} suffix='+'>
                                        {({ countUpRef }) => (
                                            <h4>
                                                <span ref={countUpRef} />                                                
                                            </h4>
                                        )}
                                    </CountUp>
                                    <p>Happy Clients</p>                                
                            </Col>
                            </VisibilitySensor>
                        </Row>
                    </Col>                    
                </Row>
            </Container>
        </section>
    );
}