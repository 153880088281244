import React, { useState } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { Link } from 'react-router-dom';
import bigdata from "./assets/bigdata.png"
import angularjs from "./assets/angularjs.png"
import codeIgniter from "./assets/codeIgniter.png"
import javascript from "./assets/javascript.png"
import laravel from "./assets/laravel.png"
import nodejs from "./assets/nodejs.png"
import python from "./assets/python.png"
import react from "./assets/react.png"
import typescript from "./assets/typescript.png"
import visualstudio from "./assets/visualstudio.png"
import vuejs from "./assets/vuejs.png"
import wordpress from "./assets/wordpress.png"
import shopify from "./assets/shopify.png"
import woocommerce from "./assets/woocommerce.png"
import ionic from "./assets/ionic.png"
import xamarin from "./assets/xamarin.png"
import ai from "./assets/ai.png"
import deeplearning from "./assets/deeplearning.png"
import aws from "./assets/aws.png"




export default function TechExpert() {

    const [expand, updateExpanded] = useState(false);

    const webdev = [
        { id: 1, name: 'Angular.js', image: angularjs, link: '/angularjs' },
        { id: 2, name: 'CodeIgniter', image: codeIgniter, link: '' },        
        { id: 3, name: 'Laravel', image: laravel, link: '/laravel' },
        { id: 4, name: 'Node.js', image: nodejs, link: '/nodejs' },
        { id: 5, name: 'React', image: react, link: '/reactjs' },        
        { id: 6, name: 'Vue.js', image: vuejs, link: '/vuejs' },
        { id: 7, name: 'Python', image: python, link: '/python' },
        { id: 8, name: 'TypeScript', image: typescript, link: '/typescript' },
        { id: 9, name: '.Net', image: visualstudio, link: '' },
        { id: 10, name: 'JavaScript', image: javascript, link: '' }
    ]
    const cms = [
        { id: 1, name: 'Wordpress', image: wordpress, link: '' },
        { id: 2, name: 'Shopify', image: shopify, link: '' },
        { id: 3, name: 'WooCommerce', image: woocommerce, link: '' }
    ]
    
    const mobile = [
        { id: 1, name: 'Ionic', image: ionic, link: '/ionic-app' },
        { id: 2, name: 'Xamarin', image: xamarin, link: '/xamarin-app' },
        { id: 3, name: 'React Native', image: react, link: '/react-native-app' }
    ]
    const leadingtech = [
        { id: 1, name: 'Artificial Intelligence(AI)', image: ai, link: 'ai' },
        { id: 2, name: 'Deep Learning', image: deeplearning, link: '/datascience' },
        { id: 3, name: 'Big Data Analytics', image: bigdata, link: '/datascience' },
        { id: 3, name: 'aws', image: aws, link: '/cloud' }
    ]

    
    return (
                   
                <Container className="techexpert-content">
                   
                    <h2>Our Technology Expertise</h2>
                    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
                        <h4>Web Development</h4>
                        {
                            webdev.map((item, index) => <Col xs={4} md={2} className="tech-icons"><Link className='link' as={Link} to={item.link} onClick={() => updateExpanded(false)}>
                            <img src={item.image} style={{ height: "10vh" }} />
                            <h5>{item.name}</h5>
                            </Link></Col>)
                        }
                    </Row>
                    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
                        <h4>Mobile App Development</h4>
                        {
                            mobile.map((item, p) => <Col xs={4} md={2} className="tech-icons"><Link className='link' as={Link} to={item.link} onClick={() => updateExpanded(false)}>
                                <img src={item.image} style={{ height: "10vh" }} />
                                <h5>{item.name}</h5>
                                </Link></Col>)
                        }

                    </Row>
                    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
                        <h4>Ecommerce & CMS</h4>
                        {
                            cms.map((item, j) => <Col xs={4} md={2} className="tech-icons"><Link className='link' as={Link} to={item.link} onClick={() => updateExpanded(false)}>
                                <img src={item.image} style={{ height: "10vh" }} />
                                <h5>{item.name}</h5>
                                </Link></Col>)
                        }

                    </Row> 
                    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
                        <h4>Trending Technology</h4>
                        {
                            leadingtech.map((item, i) => <Col xs={4} md={2} className="tech-icons"><Link className='link' as={Link} to={item.link} onClick={() => updateExpanded(false)}>
                                <img src={item.image} style={{ height: "10vh" }} />
                                <h5>{item.name}</h5>
                                </Link></Col>)
                        }
                    </Row>
                </Container>
            
    );
}