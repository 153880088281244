import React, { useState } from 'react';
import { Form, Button } from "react-bootstrap";

export default function ContactFrom() {

    const [status, setStatus] = useState("Submit");
    const handleSubmit = async (e) => {
        e.preventDefault();
        setStatus("Sending...");
        const { name, email, phone, company, message } = e.target.elements;
        let details = {
            name: name.value,
            email: email.value,
            phone: phone.value,
            company: company.value,
            message: message.value,
        };
        let response = await fetch("http://localhost:5000/contact", {
            method: "POST",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
            },
            body: JSON.stringify(details),
        });
        setStatus("Submit");
        let result = await response.json();
        alert(result.status);
    };

    return (
        <>
            <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="contactname">
                    <Form.Control type="text" id="name" placeholder="Your Name*" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="contactemail">
                    <Form.Control type="text" id="email" placeholder="Work Email* [name@example.com]" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="contactph">
                    <Form.Control type="text" id="phone" placeholder="Mobile Number*" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="companyname">
                    <Form.Control type="text" id="company" placeholder="Company Name" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="yourquery">
                    <Form.Control className="msg" id="message" as="textarea" rows={3} placeholder="Your Message / requirements" />
                </Form.Group>
                <Button className="glow-on-hover btn btn-default btn-oval btn-xl" variant="info" type="submit">{status}</Button>
            </Form>
        </>
    );

}