import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaArrowRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';

export default function ProjectDiscuss() {
    const [expand, updateExpanded] = useState(false);
    return (
        <section>
            <Container className='project-dicuss-content'>
                <Row className="justify-content-md-center">
                    <Col md={12}>
                        <Row className="project-center">
                            <Col md={8} className='project-dicuss'>
                                <h4>Let's Discuss Your Project</h4>
                                <p>Get free consultation and let us know your project idea to turn it into an amazing digital product.</p>
                                <Link className='glow-on-hover btn btn-default btn-oval btn-xl' as={Link} to="/contact" onClick={() => updateExpanded(false)}>Talk to Our Experts  <FaArrowRight size={12} color='bisque' /></Link>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );

}