import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Title from '../Header/Title';
import TechExpert from '../Home/TechExpert';
import ChooseUs from './ChooseUs';
import Testimonial from '../Testimonial';
import NewsLetter from '../Newsletter/NewsLetter';
import SolutionHire from '../Contact/SolutionHire';
import ProjectDiscuss from '../Contact/ProjectDiscuss';
import ProductHire from '../Contact/ProductHire';

export default function EnterpriseMobility() {

    return (
        <section>
            <Container fluid className="page-design-content" id="page">
                <Title>Enterprise App</Title>
                <Col className='inerpage-title'>
                    <h4 className='section-title'>What we do</h4>
                </Col>
                <Row className="justify-content-md-center">
                    <Col md={8} className='page-design-title'>
                        <h4>Empower Your Business With Our Enterprise Mobility Solutions</h4>
                        <p>MESD Technology offers a full spectrum of enterprise mobility solutions that provide innovative methods for engagement and system integration complemented by the cutting edge mobile technology that drives multi-type industries and their associated businesses forward.</p>
                        <p>MESD Technology strives to provide highly customized enterprise app develop for today's businesses. If your startup is taking it to the next level and needs enterprise-level app development for the mobile platform, MindInventory will harness our talented engineers and developers to build the enterprise mobility solutions you need to enhance your business.</p>
                    </Col>
                    <Col md={6} className='page-design-mid'>
                        <h5>Achieve your Business Goals with Our Enterprise Application Development Services</h5>
                        <p>Our proficient team of app developers have in-depth and profound knowledge of enterprise mobility solutions and delivering unmatched services. Below are some of our enterprise app development services:</p>
                        <Col md={8} className='design-types'>
                            <h4>Custom Enterprise App Development</h4>
                            <h4>UI/UX Design</h4>
                            <h4>Mobile Application Migration</h4>
                            <h4>Software Integration</h4>
                            <h4>Mobile POS Development</h4>                            
                        </Col>
                    </Col>
                </Row>
                <ProjectDiscuss />
                <ChooseUs />
                <SolutionHire />
                <TechExpert />
                <ProductHire />
                <NewsLetter />
                <Testimonial />
            </Container>            
        </section>
    );

}