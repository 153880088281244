import React from 'react';
import { Container } from 'react-bootstrap';
import HeroSection from '../Header/HeroSection';
import NewsLetter from '../Newsletter/NewsLetter';
import Testimonial from '../Testimonial';
import HomeAbout from './HomeAbout';
import ServiceSection from './ServicesSection';
import TechnologyExpert from './TechnologyExpert';
import ProductHire from '../Contact/ProductHire';
import IndustrySolution from '../solutions/IndustrySolution';
import ProjectDiscuss from '../Contact/ProjectDiscuss';
import SolutionHire from '../Contact/SolutionHire';
import TrendingTech from './TrendingTech';
// import OuritProducts from './OuritProducts';




function Home() {
    return (
        <section>
            <Container fluid className="home-section" id="home">
                <HeroSection />
                <HomeAbout />
                {/* <OuritProducts /> */}
                <ServiceSection />
                <TechnologyExpert />
                <ProjectDiscuss />
                <IndustrySolution />
                <TrendingTech />
                <ProductHire />               
                <Testimonial />
                <NewsLetter />
                <SolutionHire />
            </Container>
        </section>
    );
}
export default Home;