import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Title from '../Header/Title';
import TechExpert from '../Home/TechExpert';
import ChooseUs from './ChooseUs';
import Testimonial from '../Testimonial';
import NewsLetter from '../Newsletter/NewsLetter';
import ProjectDiscuss from '../Contact/ProjectDiscuss';

export default function Agricultural() {

    return (
        <section>
            <Container fluid className="page-design-content" id="page">
                <Title>Agricultural</Title>
                <Col className='inerpage-title'>
                    <h4 className='section-title'>What we do</h4>
                </Col>
                <Row className="justify-content-md-center">
                    <Col md={8} className='page-design-title'>
                        <h4>Revolutionizing the Agriculture Industry with Smart Farming</h4>
                        <p>The entire Agri value chain from seeding, growing, reaping, processing, distributing across the supply chain, getting it on the shelves and then to the consumer needs extreme visibility, agility to respond to changing conditions, operational efficiencies, and use of innovative technologies. Increasingly technology is playing a pivotal role in the efficient and effective management of the Agri value chain. Entities in the value chain are evolving rapidly, and there is tremendous potential in managing the agribusiness ecosystem better by increasing farming efficiencies and transparent food supply chains. Agribusinesses are acting on some of these breakthrough technological trends, including IoT, automation, wireless, AI, and ML, to become agile and efficient digital enterprises.</p>
                        <p>From digital agriculture, data-driven mechanization, Agri planning and operations, food supply chain-livestock management Agri trading, & traceability, the entire farm-to-factory-to-fork landscape is increasingly becoming technology-driven. Agribusinesses can leverage technology to drive efficiencies, higher output, and productivity and realize the sustainable agribusiness vision.</p>
                    </Col>
                    <Col md={6} className='page-design-mid'>
                        <h5>A solution that covers all critical operational areas in agro industry</h5>
                        <p>The farming industry faces tough challenges including hostile terrain, isolated locations and extreme weather. Irrigation and climate control are key to optimal agricultural growth. Constant surveillance over irrigation flows, humidity and temperature levels can increase the efficiency of any agricultural enterprise.</p>
                        <Col md={8} className='design-types'>
                            <h4>Harvest Planning & Optimization</h4>
                            <h4>Agro Operations Control</h4>
                            <h4>Procurement Planning</h4>
                            <h4>Inbound Control</h4>
                            <h4>Material Staging</h4>
                            <h4>Material Execution Control</h4>
                            <h4>Picking & Packing Control</h4>
                            <h4>Inventory Forecasting & Control</h4>
                            <h4>Transport Forecast & Booking</h4>
                            <h4>ERP Integration</h4> 
                            <h4>Device Integration</h4>
                            <h4>Mobile Operation</h4>                                                        
                        </Col>
                    </Col>
                </Row>
                <ProjectDiscuss />
                <ChooseUs />
                <TechExpert />
                <NewsLetter />
                <Testimonial />
            </Container>            
        </section>
    );

}