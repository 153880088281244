import React, { useState } from 'react';
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';
import { FaArrowRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import reactnative from './assets/icons-react.svg';
import appresearch from './assets/app-research.svg';
import prototypedesign from './assets/prototype-design.svg';
import uiinteraction from './assets/ui-interaction.svg';
import uiux from './assets/ui-ux.svg';
import ionic from './assets/ionic.svg';
import xamarin from './assets/xamarin.svg';



export default function Mobileapp() {

    const [expand, updateExpanded] = useState(false);
    const mobiledevelop = [
        { id: 1, title: 'Ionic App Development', link: '/ionic-app', icon: ionic },
        { id: 2, title: 'React Native App Development', link: '/react-native-app', icon: reactnative },
        { id: 3, title: 'Xamarin App Development', link: '/xamarin-app', icon: xamarin }
    ]
    const mobiledesign = [
        { id: 1, title: 'App Research', link: '#', icon: appresearch },
        { id: 2, title: 'UI/UX Design', link: '#', icon: uiux },
        { id: 3, title: 'UI Interaction', link: '#', icon: uiinteraction },
        { id: 4, title: 'Prototype Design', link: '#', icon: prototypedesign }
    ]

    return (
        <section>
            <Container className='mobileapp-content'>
                <Row className="justify-content-md-center">
                    <Col md={12} className="mobile-app">
                        <Row className="justify-content-md-center">
                            <Col md={8} className='mobile-app-content'>
                                <Row>
                                    <Col md={6} className='mobile-app-title'>
                                        <h4>Mobile App</h4>
                                    </Col>
                                    <Col md={6} className='mobile-app-link'>
                                        <Link className='glow-on-hover btn btn-default btn-oval btn-x' as={Link} to="/mobileapp" onClick={() => updateExpanded(false)}>Explore all Mobile Services</Link>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='mobile-app-tabs'>
                                        <Tabs defaultActiveKey="development" id="uncontrolled-tab-example" className="mb-3 mobile-tab-list">
                                            <Tab eventKey="development" title="Development">
                                                <Col md={12} className='mobile-development'>
                                                    <Row>
                                                        {
                                                            mobiledevelop.map((item, i) => <Col sm={3} className="mobile-app-list" key={i}>
                                                                <img className='mobile-app-icons fluid' src={item.icon} style={{ height: "9vh" }} />
                                                                <h5>{item.title}</h5>
                                                                <Link className='btn btn-default btn-circle btn-xl' as={Link} to={item.link} onClick={() => updateExpanded(false)}><FaArrowRight size={20} color='bisque' /></Link>
                                                            </Col>)
                                                        }
                                                    </Row>
                                                </Col>
                                            </Tab>
                                            <Tab eventKey="design" title="Design">
                                                <Col md={12} className='mobile-design'>
                                                    <Row>
                                                        {
                                                            mobiledesign.map((item, i) => <Col sm={3} className="mobile-app-list" key={i}>
                                                                <img className='mobile-app-icon fluid' src={item.icon} style={{ height: "15vh" }} />
                                                                <h5>{item.title}</h5>
                                                                <Link className='btn btn-default btn-circle btn-xl' as={Link} to={item.link} onClick={() => updateExpanded(false)}><FaArrowRight size={20} color='bisque' /></Link>
                                                            </Col>)
                                                        }
                                                    </Row>
                                                </Col>
                                            </Tab>
                                        </Tabs>
                                    </Col>
                                </Row>
                                <Col md={6} className='mobile-display-link'>
                                    <Link className='glow-on-hover btn btn-default btn-oval btn-x' as={Link} to="/contact" onClick={() => updateExpanded(false)}>Explore all Mobile Services</Link>
                                </Col>
                            </Col>
                            <Col md={4} className='web-develop-para'>

                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    )

}