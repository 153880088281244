import React, { useState } from 'react';
import {Link} from 'react-router-dom';
import { Container, Row, Col, Tab, TabContainer, TabContent, TabPane, Nav } from 'react-bootstrap';
import { FaArrowRight } from 'react-icons/fa';

import fintech from './assets/fintech.png';
import fintechone from './assets/fintechone.png';
import fintechtwo from './assets/fintechtwo.png';
import retail from './assets/retail.png';
import retailone from './assets/retailone.png';
import retailtwo from './assets/retailtwo.png';
import fit from './assets/fit.png';
import fitone from './assets/fitone.png';
import fittwo from './assets/fittwo.png';
import agriculture from './assets/agriculture.png';
import agricultureone from './assets/agricultureone.png';
import education from './assets/education.png';
import educationone from './assets/educationone.png';
import educationtwo from './assets/educationtwo.png';
import media from './assets/media.png';
import mediaone from './assets/mediaone.png';
import social from './assets/social.png';
import socialone from './assets/socialone.png';
import socialtwo from './assets/socialtwo.png';
import food from './assets/food.png';
import foodone from './assets/foodone.png';
import foodtwo from './assets/foodtwo.png';


export default function IndustrySolution() {
    const [expand, updateExpanded] = useState(false);
    const products = [
        {
            id: 1,
            productcode: 'one',
            productname: 'Fintech',
            productdes: 'Many who earlier held onto clunky processes now witness significant growth - thanks to our tailored financial software solutions. We digitized many run-of-the-mill tasks like automating investments, paying bills, or applying for loans.',
            link: '/fintech',
            productfetur: [{
                code1: 'Easy Money Transfer',
                code2: 'Easy Investment & Guides',
                code3: 'Manage Daily Expences And Offers'
            }],
            productimg: [{
                img1: fintech,
                img2: fintechone,
                img3: fintechtwo
            }]

        },
        {
            id: 2,
            productcode: 'two',
            productname: 'E-commerce & Retail',
            productdes: 'We help store owners, retailers, suppliers, and wholesalers digitally expand with stunning online marketplaces that reach every customer - on the web and mobile.',
            link: '/e-commerce',
            productfetur: [{
                code1: 'Manage Inventory',
                code2: 'Sales Analytics',
                code3: 'POS Solution'
            }],
            productimg: [{
                img1: retail,
                img2: retailone,
                img3: retailtwo
            }]

        },
        {
            id: 3,
            productcode: 'three',
            productname: 'Agricultural',
            productdes: 'Your agriculture enterprise needs to sustain food supply while ensuring that produce is nutritious and safe for consumption. You need to respond to shifts in diet such as veganism, dietary choices such as free range and organic produce, and culinary preferences such as food and beverage pairings.',
            link: '/agricultural',
            productfetur: [{
                code1: 'Plant and Soil Analytics',
                code2: 'Communities App',
                code3: 'POS Solution'
            }],
            productimg: [{
                img1: agriculture,
                img2: agricultureone,
                img3: fintechtwo
            }]

        },
        {
            id: 4,
            productcode: 'fore',
            productname: 'Fitness',
            productdes: 'All fitness freaks want a great instructor who can guide them better and track their daily activities. let make fitness enthusiast experience more energetic by taking your business online.',
            link: '/fitness',
            productfetur: [{
                code1: 'Online Training Sessions',
                code2: 'Fitness Tracker',
                code3: 'Smart Watch App'
            }],
            productimg: [{
                img1: fit,
                img2: fitone,
                img3: fittwo
            }]

        },
        {
            id: 5,
            productcode: 'five',
            productname: 'Food Delivery',
            productdes: 'Drive your potential customers into a comfort zone where ordering online food becomes a cakewalk with complete digital synced solutions in food delivery.',
            link: '/food-delivery',
            productfetur: [{
                code1: 'Order Tracking',
                code2: 'In-App Payments',
                code3: 'Realtime Tracking'
            }],
            productimg: [{
                img1: food,
                img2: foodone,
                img3: foodtwo
            }]

        },
        {
            id: 6,
            productcode: 'six',
            productname: 'Media & Entertainment',
            productdes: 'We all know how much entertainment important in our life or in the weekend to burn out our stress, lets give your user the best experience to take their stress out and give them a fresh mind to continue their daily routine again.',
            link: '/media-entertainment',
            productfetur: [{
                code1: 'Fast Scheduling',
                code2: 'In-App Subscription',
                code3: 'Online Entertainment'
            }],
            productimg: [{
                img1: media,
                img2: mediaone,
                img3: fintechtwo
            }]

        },
        {
            id: 7,
            productcode: 'seven',
            productname: 'Education & E-Learning',
            productdes: 'Whether you need to develop an education app, eLearning portal, or curriculum-based platform, we’ve got you covered. To deliver custom eLearning solutions that move schools, colleges, and education courses online.',
            link: '/education',
            productfetur: [{
                code1: 'Live Classes',
                code2: 'E-Library for Managing Notes & Tutorials',
                code3: 'Online Examination'
            }],
            productimg: [{
                img1: education,
                img2: educationone,
                img3: educationtwo
            }]

        },
        {
            id: 8,
            productcode: 'eight',
            productname: 'Business',
            productdes: 'Using enterprise mobility solutions, businesses have tremendous opportunities to modernize their operations to defeat challenges, boost productivity, enrich customer services and earn more revenue.',
            link: '/business',
            productfetur: [{
                code1: 'Sales & Marketing',
                code2: 'Employee Management',
                code3: 'HR Portals'
            }],
            productimg: [{
                img1: fintech,
                img2: fintechone,
                img3: fintechtwo
            }]

        },
        {
            id: 9,
            productcode: 'nine',
            productname: 'Social Networking',
            productdes: 'Social media app helps you to expand your business network, enhance brand loyalty and credibility, boost conversion rate and revenue as well as improve customer service and satisfaction.',
            link: '/social-networking',
            productfetur: [{
                code1: 'Messaging, Calling App',
                code2: 'Communities App',
                code3: 'Job Posting App'
            }],
            productimg: [{
                img1: social,
                img2: socialone,
                img3: socialtwo
            }]

        }

    ]

    return (
        <section>
            <Container className='industry-solution-content'>
                <h4 className='section-title'>Industry wise solutions</h4>
                <Row className="justify-content-md-center">
                    <Col md={10} className='industry-solution'>
                        <Tab.Container id="left-tabs-example" defaultActiveKey="one">
                            <Row>
                                <Col sm={3} className="tabname">
                                    <Nav variant="pills" className="flex-column">
                                        {
                                            products.map((item) => <Nav.Item>
                                                <Nav.Link className='solnav' eventKey={item.productcode}>{item.productname}</Nav.Link>
                                            </Nav.Item>)
                                        }
                                    </Nav>
                                </Col>
                                <Col sm={9} className="tabcontent">
                                    <Tab.Content>
                                        {
                                            products.map((item, index) => <Tab.Pane eventKey={item.productcode}>
                                                <Col md={12} className="industry-solution-main" key={index}>
                                                    <Row>
                                                        <Col md={12} className="industry-solution-col-1">
                                                            <Row>
                                                                <Col md={8} className="industry-solution-col-content">
                                                                    <h5>{item.productname}</h5>
                                                                    <p>{item.productdes}</p>                                                                    
                                                                    {
                                                                        item.productfetur.map((subitem) =><ul className="solution-industry-icons"><li className="industry-icons">{subitem.code1}</li><li className="industry-icons">{subitem.code2}</li><li className="industry-icons">{subitem.code3}</li> </ul>)
                                                                    }                                                                   
                                                                </Col>
                                                                <Col md={4} className="industry-solution-col-btn">
                                                                    <Link className='btn btn-default btn-oval btn-xl' as={Link} to={item.link} onClick={() => updateExpanded(false)}>Explore More  <FaArrowRight size={12} color='bisque' /></Link>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col md={12} className="industry-solution-col-2">
                                                            <Row>
                                                                <Col md={8} className="industry-solution-col-img1">                   
                                                                
                                                                {
                                                                    item.productimg.map((itemimg) => <img className="industry-col-img1 fluid" src={itemimg.img1} />)
                                                                }
                                                                
                                                                </Col>                                                                
                                                                    {
                                                                        item.productimg.map((imgitem) => <Col md={4} className="industry-solution-col-img2"><Row><img className="solution-industry-col-img1 fluid" src={imgitem.img2} /></Row><Row><img className="solution-industry-col-img2 fluid" src={imgitem.img3} /></Row></Col>                                                                    
                                                                        )
                                                                    }                                                                
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </Col>

                                            </Tab.Pane>
                                            )
                                        }
                                        
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </Col>
                </Row>
            </Container>
        </section>
    );
    
}