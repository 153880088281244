import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Title from '../Header/Title';
import TechExpert from '../Home/TechExpert';
import ChooseUs from './ChooseUs';
import Testimonial from '../Testimonial';
import NewsLetter from '../Newsletter/NewsLetter';
import ProjectDiscuss from '../Contact/ProjectDiscuss';
import SolutionHire from '../Contact/SolutionHire';
import ProductHire from '../Contact/ProductHire';

export default function IBeaconapp() {

    return (
        <section>
            <Container fluid className="page-design-content" id="page">
                <Title>iBeacon App</Title>
                <Col className='inerpage-title'>
                    <h4 className='section-title'>What we do</h4>
                </Col>
                <Row className="justify-content-md-center">
                    <Col md={8} className='page-design-title'>
                        <h4>Revolutionize Your Businesses with Next-Gen iBeacon App Development Solutions</h4>
                        <p>MESD Technology, an iBeacon app development company enlists the latest technology to provide the right solutions for your iBeacon app. We use our knowledge and understanding in a view to helping you unlock the power, that iBeacon provides using Bluetooth Low Energy.</p>
                        <p>By using the required expertise and skills, we provide iBeacon app development solutions that are leading in the market. We use indoor positioning application; therefore, we deliver more than content and include the closeness of objects in the physical world. Our business thrives when we help our client to succeed. Our clients can use the iBeacon apps to engage their potential clients at a new level.</p>
                    </Col>
                    <Col md={6} className='page-design-mid'>
                        <h5>Custom iBeacon App Development Services to Deliver Personalized User Experiences</h5>
                        <p>MESD Technology offer scalable iBeacon app development services for startups, mid-scale businesses & enterprises across the globe. We have created many apps using beacon technology for the global clients. As a leading iBeacon app development company we deliver a wide range of services as per your business needs.</p>
                        <Col md={8} className='design-types'>
                            <h4>Mobile Payments</h4>
                            <h4>Navigations & Maps</h4>
                            <h4>Vehicle Tracking and Monitoring</h4>
                            <h4>Information on Location</h4>
                            <h4>Discount Notifications</h4>                            
                        </Col>
                    </Col>
                </Row>
                <ProjectDiscuss />
                <ChooseUs />
                <TechExpert />
                <SolutionHire />
                <NewsLetter />
                <Testimonial />
                <ProductHire />
            </Container>            
        </section>
    );

}