import React from 'react';
import Title from '../Header/Title';
import OverView from './OverView';
import { Container } from 'react-bootstrap';
import Benefits from './Benefits';
import SuccessNo from './SuccessNo';
import SolutionHire from '../Contact/SolutionHire';
import ProductHire from '../Contact/ProductHire';
import NewsLetter from '../Newsletter/NewsLetter';
import Testmonial from '../Testimonial';
import TrendingTech from '../Home/TrendingTech';
import TechnologyExpert from '../Home/TechnologyExpert';
import ChooseUs from '../Pages/ChooseUs';

export default function AboutUs() {

    return (
        <section>
            <Container fluid className="about-section" id="about">
                <Title>About Us</Title>
                <OverView />
                <Benefits />
                <SuccessNo />
                <TrendingTech />
                <TechnologyExpert />
                <ChooseUs />
                <ProductHire />
                <NewsLetter />
                <Testmonial />
                <SolutionHire />
            </Container>
        </section>
    );
}