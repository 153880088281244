import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaArrowRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import Slide from 'react-reveal/Slide';


export default function HomeAbout() {
    const [expand, updateExpanded] = useState(false);
    const [focus, setFocus] = React.useState(false);
    const [isVisible, setVisible] = React.useState(false);
    return (
        <section>
            <Container fluid className="home-about-section" id="about">
                <h4 className='section-title'>Who we are</h4>
                <Row style={{ justifyContent: "center", padding: "10px" }}>
                    <Col md={6} className='home-about-content'>
                        <h3>We’ve been delivering extraordinary eCommerce, Web, Mobile, CRM & Cloud Development, and inspirational Design robust solutions for over 5 years!.</h3>
                        <Col className='home-about-para'>
                            <p>At MESD Technology, we deliver high-quality and efficient web solutions for all kinds of businesses. Whether you need an expert for web development, mobile app development, ecommerce or creative design; we have a team of professionals who handle your project with outstanding dedication and professionalism.</p>
                        </Col>
                        <Link className='glow-on-hover btn btn-default btn-oval btn-xl' as={Link} to="/about" onClick={() => updateExpanded(false)}>About Us <FaArrowRight size={12} color='bisque'></FaArrowRight></Link>
                    </Col>
                    <Col md={2} className='home-about-counter'>
                        <Slide left cascade>
                            <Col className='home-counts1'>
                                <CountUp start={focus ? 0 : null} end={100} duration={3} redraw={true} suffix='+'>
                                    {({ countUpRef }) => (
                                        <h4>
                                            <span ref={countUpRef} />
                                            <VisibilitySensor
                                                onChange={isVisible => {
                                                    if (isVisible) {
                                                        setFocus(true);
                                                    }
                                                }}
                                            >
                                            </VisibilitySensor>
                                        </h4>
                                    )}
                                </CountUp>
                                <p>Happy Clients</p>
                            </Col>
                            <VisibilitySensor onChange={isVisible => {
                                                    if (isVisible) {
                                                        setFocus(true);
                                                    }
                                                }}
                                            >
                            <Col className='home-counts2'>
                                <CountUp start={focus ? 0 : null} end={500} duration={3} redraw={true} suffix='+'>
                                    {({ countUpRef }) => (
                                        <h4>
                                            <span ref={countUpRef} />                                            
                                            
                                        </h4>
                                    )}
                                </CountUp>
                                <p>Projects Completed</p>
                            </Col>
                            </VisibilitySensor>
                            <VisibilitySensor onChange={isVisible => {
                                                    if (isVisible) {
                                                        setFocus(true);
                                                    }
                                                }}
                                            >
                            <Col className='home-counts3'>
                                <CountUp start={focus ? 2000 : null} end={2100} duration={3} redraw={true} suffix='+'>
                                    {({ countUpRef }) => (
                                        <h4>
                                            <span ref={countUpRef} /> 
                                        </h4>
                                    )}
                                </CountUp>
                                <p>Tasks Completed</p>
                            </Col>
                            </VisibilitySensor>
                        </Slide>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}