import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Title from '../Header/Title';
import TechExpert from '../Home/TechExpert';
import ChooseUs from './ChooseUs';
import Testimonial from '../Testimonial';
import NewsLetter from '../Newsletter/NewsLetter';
import ProjectDiscuss from '../Contact/ProjectDiscuss';

export default function Python() {

    return (
        <section>
            <Container fluid className="page-design-content" id="page">
                <Title>Python</Title>
                <Col className='inerpage-title'>
                    <h4 className='section-title'>What we do</h4>
                </Col>
                <Row className="justify-content-md-center">
                    <Col md={8} className='page-design-title'>
                        <h4>We provide customized Python development solutions to business firms & entrepreneurs.</h4>
                        <p>The dynamic and flexible nature of the Python programming language makes it the first choice for your website. It is an interpreted, general-purpose, high-level programming language based on OOPs. Python focuses on DRY (Don’t Repeat Yourself) and RAD (Rapid Application Development).</p>                        
                    </Col>
                    <Col md={6} className='page-design-mid'>
                        <h5>We bring you a spectrum of Python development services, making the web apps & sites scalable.</h5>
                        <p>As a leading offshore Python development company, MindInventory specializes in the language and develops robust websites and apps. Our python web development services are always oriented toward translating your ideas into reality. It is important for you to get the technologies updated from time to time. To that end, you can hire python developers to migrate from the older versions to the newest ones. We also offer migration services from other programming languages like Java and PHP to Python.</p>
                        <Col md={8} className='design-types'>
                            <h4>Python Website Development</h4>
                            <h4>Python Web Application Development</h4>
                            <h4>Migration Services</h4>
                            <h4>Machine Learning</h4>
                            <h4>Deep Learning</h4>
                            <h4>Python Consulting Services</h4>                                                        
                        </Col>
                    </Col>
                </Row>
                <ProjectDiscuss />
                <ChooseUs />
                <TechExpert />
                <NewsLetter />
                <Testimonial />
            </Container>            
        </section>
    );

}