import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Title from '../Header/Title';
import TechExpert from '../Home/TechExpert';
import ChooseUs from './ChooseUs';
import Testimonial from '../Testimonial';
import NewsLetter from '../Newsletter/NewsLetter';
import ProjectDiscuss from '../Contact/ProjectDiscuss';

export default function XamarinApp() {

    return (
        <section>
            <Container fluid className="page-design-content" id="page">
                <Title>Xamarin App</Title>
                <Col className='inerpage-title'>
                    <h4 className='section-title'>What we do</h4>
                </Col>
                <Row className="justify-content-md-center">
                    <Col md={8} className='page-design-title'>
                        <h4>We help businesses to build high-performance cross platform apps using Xamarin.</h4>
                        <p>Statistics suggest that “80% of enterprises are focusing on developing applications that support multiple platforms like iOS and Android. Cross-platform apps that use the same code base are flexible, easy to use and are most cost-effective.” Many large mobile apps of native flair that runs on Apple and Android phones have relied on Rensol cost-effective services by leveraging Xamarin Mobile Application Development service named ‘Cross-Platform App Development Xamarin’.</p>                        
                    </Col>
                    <Col md={6} className='page-design-mid'>
                        <h5>Our High Quality Xamarin Mobile Application Development Services</h5>
                        <p>With Xamarin as the technology platform from Microsoft, you make sure that your app is always up to date with the same experience across iOS and Android with the same codebase. If you use native development environments then you need to have two teams one having experience in objective C for iOS and the other having expertise in Android Studio. For Microsoft development teams same team can be utilized to create web applications and mobile apps.</p>
                        <Col md={8} className='design-types'>
                        <h4>Custom Mobile App Development</h4>
                            <h4>Xamarin Migration</h4>
                            <h4>Xamarin Consulting</h4>
                            <h4>Mobile App Backend & API Development</h4>
                            <h4>Testing and QA Solutions</h4>
                            <h4>Xamarin Development for iOS, Android and Windows app</h4>
                            <h4>Xamarin Cross-Platform Development</h4>                            
                        </Col>
                    </Col>
                </Row>
                <ProjectDiscuss />
                <ChooseUs />
                <TechExpert />
                <NewsLetter />
                <Testimonial />
            </Container>            
        </section>
    );

}