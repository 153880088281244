import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Title from '../Header/Title';
import TechExpert from '../Home/TechExpert';
import ChooseUs from './ChooseUs';
import Testimonial from '../Testimonial';
import NewsLetter from '../Newsletter/NewsLetter';
import ProjectDiscuss from '../Contact/ProjectDiscuss';

export default function SocialNetworking() {

    return (
        <section>
            <Container fluid className="page-design-content" id="page">
                <Title>E-Learning</Title>
                <Col className='inerpage-title'>
                    <h4 className='section-title'>What we do</h4>
                </Col>
                <Row className="justify-content-md-center">
                    <Col md={8} className='page-design-title'>
                        <h4>The leading platform to extract real business value from social</h4>
                        <p>Social media apps become a key utility of people today as it gives them several benefits over keeping them connected with their personal and professional group. Make your Social Media App Popular with Efficient Dating and Social Networking App Development Solutions.</p>
                        <p>Our all-in-one social media management platform unlocks the full potential of social to transform not just your marketing strategy—but every area of your organization.</p>
                    </Col>
                    <Col md={6} className='page-design-mid'>
                        <h5>A powerful solution for social media management</h5>
                        <p>Our broad ranging social networking app development solutions help you to expand your business network, enhance brand loyalty and credibility, boost conversion rate and revenue as well as improve customer service and satisfaction.</p>
                        <Col md={8} className='design-types'>
                            <h4>Forum, Blogging, Publishing Apps</h4>
                            <h4>Social Networking App Development</h4>
                            <h4>Sites for Shopping, Consumer Review</h4>
                            <h4>Media Sharing Social Apps</h4>
                            <h4>Relationship Based Apps</h4>
                            <h4>Career-based Apps</h4>
                            <h4>Dating App Development</h4>
                            <h4>Social Media Apps for Friends</h4>
                            <h4>Community App Development</h4>                             
                        </Col>
                    </Col>
                </Row>
                <ProjectDiscuss />
                <ChooseUs />
                <TechExpert />
                <NewsLetter />
                <Testimonial />
            </Container>            
        </section>
    );

}