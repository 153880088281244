import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Title from '../Header/Title';
import TechExpert from '../Home/TechExpert';
import ChooseUs from './ChooseUs';
import Testimonial from '../Testimonial';
import NewsLetter from '../Newsletter/NewsLetter';
import ProjectDiscuss from '../Contact/ProjectDiscuss';

export default function Education() {

    return (
        <section>
            <Container fluid className="page-design-content" id="page">
                <Title>E-Learning</Title>
                <Col className='inerpage-title'>
                    <h4 className='section-title'>What we do</h4>
                </Col>
                <Row className="justify-content-md-center">
                    <Col md={8} className='page-design-title'>
                        <h4>A Proficient and Enhanced On-demand E-Learning App</h4>
                        <p>With digitalization happening in every nook and corner of the world, we have realized that the e-Learning industry is no different and needs a route to channel learning and reach the masses. More and more parents are thinking of homeschooling their children or help them get home tuitions without having them step out of their homes. This is when they turn to the e-Learning apps that are not only different but choose the easiest ways of educating children. We at Arka Softwares, have a team of the experienced elearning app developers who understand the importance of e-Learning and conceptualize a plan that helps you go strong in the long run.</p>
                        <p>We believe that people look up to the e-Learning apps for knowledge and information, therefore, it should be well integrated with features that make it easy to use and cater to all the needs of the students and users.</p>
                    </Col>
                    <Col md={6} className='page-design-mid'>
                        <h5>Our Education App Development Solutions.</h5>
                        <p>Clients love our work because of our ability to develop unique learner journeys end to end. We’ve created eLearning portals that have connected hundreds of students with skilled tutors worldwide. From online classrooms, play-based learning, video-based learning to curriculum-based apps - we’ve handled it all. Each of our solutions has increased student engagement. Companies struggling to fit the pieces together to build eLearning apps have benefited by choosing us as their tech partner.</p>
                        <Col md={8} className='design-types'>
                            <h4>Online Training Apps</h4>
                            <h4>Learning App for Coaching Classes</h4>
                            <h4>Mobility Solution for College & University</h4>
                            <h4>Learning App for Nursery & Preschool</h4>
                            <h4>Knowledge-based Educational App</h4>                            
                        </Col>
                    </Col>
                </Row>
                <ProjectDiscuss />
                <ChooseUs />
                <TechExpert />
                <NewsLetter />
                <Testimonial />
            </Container>            
        </section>
    );

}