import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Title from '../Header/Title';
import TechExpert from '../Home/TechExpert';
import ChooseUs from './ChooseUs';
import Testimonial from '../Testimonial';
import NewsLetter from '../Newsletter/NewsLetter';
import ProjectDiscuss from '../Contact/ProjectDiscuss';

export default function Fitness() {

    return (
        <section>
            <Container fluid className="page-design-content" id="page">
                <Title>Fitness</Title>
                <Col className='inerpage-title'>
                    <h4 className='section-title'>What we do</h4>
                </Col>
                <Row className="justify-content-md-center">
                    <Col md={8} className='page-design-title'>
                        <h4>Enhance your business proficiency & smarter your lives using Hi-end IoT App Development.</h4>
                        <p>IoT is a connectivity of all physical devices which are connected through internet and able to exchange (send and receive) data. The objects include vehicles, smart phones, gadgets, wearable devices, home appliances, and many other physical devices as well as human. IoT app works as a bridge enables physical devices to communicate with each other.</p>
                        <p>As a prominent IoT solutions and services provider, MindInventory offers hi-end IoT Application Development services based on bespoke needs of customers from different industry verticals.</p>
                    </Col>
                    <Col md={6} className='page-design-mid'>
                        <h5>Innovative IoT App Development Services to solve the real-world problems.</h5>
                        <p>We proffer a complete range of Internet of Things development services using the most advanced technology and proficiency of our team of veteran IoT app developers. Our expert IoT solutions help you to transform your devices into smart devices. Our range of services includes:</p>
                        <Col md={8} className='design-types'>
                            <h4>IoT App Development</h4>
                            <h4>Connectivity With Wearable Devices</h4>
                            <h4>Voice Enabled Technology Solutions</h4>
                            <h4>IoT Cloud Platform</h4>
                            <h4>IoT Gateway Development</h4>                            
                        </Col>
                    </Col>
                </Row>
                <ProjectDiscuss />
                <ChooseUs />
                <TechExpert />
                <NewsLetter />
                <Testimonial />
            </Container>            
        </section>
    );

}