import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Title from '../Header/Title';
import TechExpert from '../Home/TechExpert';
import ChooseUs from './ChooseUs';
import Testimonial from '../Testimonial';
import NewsLetter from '../Newsletter/NewsLetter';
import ProjectDiscuss from '../Contact/ProjectDiscuss';

export default function MediaEntertainment() {

    return (
        <section>
            <Container fluid className="page-design-content" id="page">
                <Title>Media & Entertainment</Title>
                <Col className='inerpage-title'>
                    <h4 className='section-title'>What we do</h4>
                </Col>
                <Row className="justify-content-md-center">
                    <Col md={8} className='page-design-title'>
                        <h4>We provide mobility solution for media and entertainment industry.</h4>
                        <p>There is a huge shift in the media and entertainment industry as technology paves the way for consumers to access their favorite content at their fingertips. With rapidly changing consumer behavior, evolve your media generation and deployment process with software solutions.</p>
                        <p>The rapid advances in digital content processing, the Internet, IT and cloud technologies are allowing content creators and distributors in the media and entertainment industry to work more closely and effectively to provide consumers an ever-increasing array of options. All of this is happening at an accelerating rate, thus increasing the scope of consumer-friendly services being introduced. Certainly, this is an exciting time for the media and entertainment industry.</p>
                    </Col>
                    <Col md={6} className='page-design-mid'>
                        <h5>Our Media & Entertainment App Development Solutions.</h5>
                        <p>Develop a custom content management system with the help of our expert developers and provide the perfect solution for your users. Increase your teams’ efficiency with the help of automation on various customer touchpoints and streamline communication channels.</p>
                        <Col md={8} className='design-types'>
                            <h4>Content Management</h4>
                            <h4>Connectivity With Wearable Devices</h4>
                            <h4>Content Process Automation</h4>
                            <h4>Helpdesk and Ticketing Solution</h4>
                            <h4>Customer Communications & Management</h4>                            
                        </Col>
                    </Col>
                </Row>
                <ProjectDiscuss />
                <ChooseUs />
                <TechExpert />
                <NewsLetter />
                <Testimonial />
            </Container>            
        </section>
    );

}