import React from 'react';
import Title from '../Header/Title';
import { Container } from 'react-bootstrap';
import SolutionOverView from './SolutionOverView';
import ProjectDiscuss from '../Contact/ProjectDiscuss';
import ProductHire from '../Contact/ProductHire';
import DemandSolutions from './DemandSolution';
import IndustrySolution from './IndustrySolution';
import SolutionHire from '../Contact/SolutionHire';
import TechExpert from '../Home/TechExpert';
import NewsLetter from '../Newsletter/NewsLetter';
import Testimonial from '../Testimonial';


export default function Solutions() {

    return(
        <section>
            <Container fluid className="solution-section" id="solution">
                <Title>Our Solutions</Title>
                <SolutionOverView />
                <ProjectDiscuss />
                <ProductHire />
                <DemandSolutions />
                <IndustrySolution />
                <SolutionHire />
                <TechExpert />
                <NewsLetter />
                <Testimonial />
            </Container>
        </section>
    );
}