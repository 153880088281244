import React, { useState, useEffect } from "react";
import logo from './logo.svg';
import './App.css';
import './style.css';
import Footer from './components/Footer/Footer';
import NavBar from "./components/Header/NavBar";
import { BrowserRouter as Router, Route, Routes, Switch } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Services from "./components/services/Services";
import Solutions from "./components/solutions/Solutions";
import AboutUs from "./components/About/AboutUs";
import ContactUs from "./components/Contact/ContactUs";
import Home from "./components/Home/Home";
import Webapplication from './components/Pages/Webapplication';
import Uiuxdesign from './components/Pages/Uiuxdesign';
import Mobileapp from './components/Pages/Mobileapp';
import Crm from './components/Pages/Crm';
import Ai from './components/Pages/Ai';
import Datascience from './components/Pages/Datascience';
import Cloud from './components/Pages/Cloud';
import Iotappdevelopment from "./components/Pages/Iot-app-development";
import WearableApp from "./components/Pages/Wearable-app-development";
import IBeaconapp from "./components/Pages/iBeacon -app-development";
import EnterpriseMobility from "./components/Pages/Enterprise-mobility";
import SoftwareSolution from "./components/Pages/Software-solutions";
import ProductEngineering from "./components/Pages/Product-engineering";
import DigitalTransformation from "./components/Pages/Digital-transformation";
import Fintech from "./components/Pages/Fintech";
import Ecommerce from "./components/Pages/E-commerce";
import Agricultural from "./components/Pages/Agricultural";
import Fitness from "./components/Pages/Fitness"
import FoodDelivery from "./components/Pages/FoodDelivery";
import MediaEntertainment from "./components/Pages/Media-Entertainment";
import SocialNetworking from "./components/Pages/Social-Networking";
import Education from "./components/Pages/Education";
import Business from "./components/Pages/Business";
import AngularJs from "./components/Pages/AngularJs";
import ReactJs from "./components/Pages/ReactJs";
import VueJs from "./components/Pages/VueJs";
import NodeJs from "./components/Pages/NodeJs";
import Laravel from "./components/Pages/Laravel";
import Python from "./components/Pages/Python";
import IonicApp from "./components/Pages/IonicApp";
import ReactNativeApp from "./components/Pages/ReactNativeApp";
import XamarinApp from "./components/Pages/XamarinApp";
import TypeScript from "./components/Pages/TypeScript";
import Ondemand from "./components/Pages/Ondemand";




function App() {
  const [load, upadateLoad] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      upadateLoad(false);
    }, 1200);

    return () => clearTimeout(timer);
  }, []);
  return (
    <Router>
      <div className="App">     
        <NavBar />
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/services" element={<Services />} />
          <Route path="/solutions" element={<Solutions />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/webapplication" element={<Webapplication />} />
          <Route path="/webapplication" element={<Webapplication />} />
          <Route path="/uiuxdesign" element={<Uiuxdesign />} />
          <Route path="/mobileapp" element={<Mobileapp />} />
          <Route path="/crm" element={<Crm />} />
          <Route path="/ai" element={<Ai />} />
          <Route path="/datascience" element={<Datascience />} />
          <Route path="/cloud" element={<Cloud />} />
          <Route path="/iot-app-development" element={<Iotappdevelopment />} />
          <Route path="/wearable-app-development" element={<WearableApp />} />
          <Route path="/ibeacon-app-development" element={<IBeaconapp />} />
          <Route path="/enterprise-mobility-solution" element={<EnterpriseMobility />} />
          <Route path="/software-solution" element={<SoftwareSolution />} />
          <Route path="/product-engineering" element={<ProductEngineering />} />
          <Route path="/digital-transformation" element={<DigitalTransformation />} />
          <Route path="/fintech" element={<Fintech />} />
          <Route path="/e-commerce" element={<Ecommerce />} />
          <Route path="/agricultural" element={<Agricultural />} />
          <Route path="/fitness" element={<Fitness />} />
          <Route path="/food-delivery" element={<FoodDelivery />} />
          <Route path="/media-entertainment" element={<MediaEntertainment />} />
          <Route path="/social-networking" element={<SocialNetworking />} />
          <Route path="/education" element={<Education />} />
          <Route path="/business" element={<Business />} />
          <Route path="/angularjs" element={<AngularJs />} />
          <Route path="/reactjs" element={<ReactJs />} />
          <Route path="/vuejs" element={<VueJs />} />
          <Route path="/nodejs" element={<NodeJs />} />
          <Route path="/laravel" element={<Laravel />} />
          <Route path="/python" element={<Python />} />
          <Route path="/ionic-app" element={<IonicApp />} />
          <Route path="/react-native-app" element={<ReactNativeApp />} />
          <Route path="/xamarin-app" element={<XamarinApp />} />
          <Route path="/typescript" element={<TypeScript />} />
          <Route path="/ondemand" element={<Ondemand />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
