import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Title from '../Header/Title';
import TechExpert from '../Home/TechExpert';
import ChooseUs from './ChooseUs';
import Testimonial from '../Testimonial';
import NewsLetter from '../Newsletter/NewsLetter';
import ProjectDiscuss from '../Contact/ProjectDiscuss';

export default function Business() {

    return (
        <section>
            <Container fluid className="page-design-content" id="page">
                <Title>Business Development</Title>
                <Col className='inerpage-title'>
                    <h4 className='section-title'>What we do</h4>
                </Col>
                <Row className="justify-content-md-center">
                    <Col md={8} className='page-design-title'>
                        <h4>We provide business mobile app development solutions.</h4>
                        <p>Using the enterprise mobility solutions, businesses have tremendous opportunities to modernize their operations to defeat challenges, boost productivity, enrich customer services and earn more revenue without waiting for years to grow their business. Nowadays, for a small scale enterprise or a big multinational company, a broad range of mobile business solutions accomplishes all purposes of your business precisely as per your expectations.</p>
                        <p>We offer broad range of services for the business mobile app development based on the tailor-made needs of business. Our hi-end and cost-efficient mobile app development solution deliver businesses unmatched solutions for the swift growth of their company.</p>
                    </Col>
                    <Col md={6} className='page-design-mid'>
                        <h5>Business Mobile App Development</h5>
                        <p>As a leading wen and mobile app development company, we have expertise in successful Application Development for businesses, no matter size or type of your business. We have the experience to work with all types of industry to deliver best mobility solution that precisely meet your enterprise needs.</p>
                        <Col md={8} className='design-types'>
                            <h4>Warehouse Solution</h4>
                            <h4>HR Consultancy Management</h4>
                            <h4>Business Apps for Dairy</h4>
                            <h4>Sales & Marketing</h4>
                            <h4>Business Apps for Large Business</h4>
                            <h4>Processing House Solution</h4>
                            <h4>Accounting Professionals</h4>                            
                        </Col>
                    </Col>
                </Row>
                <ProjectDiscuss />
                <ChooseUs />
                <TechExpert />
                <NewsLetter />
                <Testimonial />
            </Container>            
        </section>
    );

}