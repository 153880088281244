import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import SubTitle from '../Header/SubTitle';
import { Link } from 'react-router-dom';
import react from '../assets/reactnative.svg';
import docker from '../assets/docker.svg';
import firebase from '../assets/firebase.svg';
import flutter from '../assets/flutter.svg';
import kubernetes from '../assets/kubernetes.svg';


export default function TrendingTech() {
    const [expand, updateExpanded] = useState(false);
    const trandingtech = [
        { id: 1, name: 'React', image: react, link: '/contact' },
        { id: 2, name: 'Flutter', image: flutter, link: '/contact' },
        { id: 3, name: 'Firebase', image: firebase, link: '/contact' },
        { id: 4, name: 'Docker', image: docker, link: '/contact' },
        { id: 5, name: 'Kubernetes', image: kubernetes, link: '/contact' }
    ]
    return (
        <section>
            <Container fluid className="trending-tech-section" id="about">
                <Row style={{ justifyContent: "center", padding: "10px" }}>
                    <SubTitle>Trending</SubTitle>
                    <h4 className='o-sub-title'>Technologies</h4>
                    <Col md={10} className='trending-tech-content'>
                        <ul className='trending-tech-icon d-flex justify-content-md-center'>
                            {
                                trandingtech.map((item, i) =><li className='trending-tech-list'>
                                <Link as={Link} to={item.link} onClick={() => updateExpanded(false)}>
                                    <span className='tech_img_main'>
                                        <img className='tranding-tech_img' src={item.image} alt='React' style={{ height: "15vh" }} />
                                    </span>
                                    <span className='d-block tech_text'>{item.name}</span>
                                </Link>
                            </li>)
                            }
                            
                            
                        </ul>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}