import React from 'react';
import { Col } from 'react-bootstrap';
import { render } from "react-dom";
import Lottie from 'react-lottie';
import color from './assets/colors.json';

export default function HeroAnimation() {
    const coloranimation = {
        loop: true,
        autoplay: true,
        animationData: color,        
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    return (
        <Col className='heroanimation'>
            <Lottie
                options={coloranimation}
                height={450}
                width={450}
            />
        </Col>
    );
}