import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Title from '../Header/Title';
import TechExpert from '../Home/TechExpert';
import ChooseUs from './ChooseUs';
import Testimonial from '../Testimonial';
import NewsLetter from '../Newsletter/NewsLetter';
import ProjectDiscuss from '../Contact/ProjectDiscuss';

export default function IonicApp() {

    return (
        <section>
            <Container fluid className="page-design-content" id="page">
                <Title>Ionic App</Title>
                <Col className='inerpage-title'>
                    <h4 className='section-title'>What we do</h4>
                </Col>
                <Row className="justify-content-md-center">
                    <Col md={8} className='page-design-title'>
                        <h4>We provide advanced & cutting edge cross platform app development solutions for your business.</h4>
                        <p>We are a leading cross platform Android, IOS app development company with a unique portfolio. Our team of experts uses the latest tools and technologies to build the industry-best cross platform apps. Hiring us not just increases your client base and business revenues but also helps you reap more advantages than your competitors.</p>                        
                    </Col>
                    <Col md={6} className='page-design-mid'>
                        <h5>End-to-End cross platform Android, IOS App Development Services to Empower Your Business</h5>
                        <p>We not just provide best-in-class Android and IOS app development services but also build solutions to transform your business. Our solutions serve a wide range of industry verticals. We encourage emerging companies as well as support established entities by engineering apps that run smoothly on any Android & IOS device.</p>
                        <Col md={8} className='design-types'>
                            <h4>Custom Ionic App Development</h4>
                            <h4>Ionic eCommerce App Development</h4>
                            <h4>Ionic Testing Services</h4>
                            <h4>Ionic App UI/UX Design</h4>
                            <h4>Ionic Migration Services</h4>                                                       
                        </Col>
                    </Col>
                </Row>
                <ProjectDiscuss />
                <ChooseUs />
                <TechExpert />
                <NewsLetter />
                <Testimonial />
            </Container>            
        </section>
    );

}