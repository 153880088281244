import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Title from '../Header/Title';
import TechExpert from '../Home/TechExpert';
import ChooseUs from './ChooseUs';
import Testimonial from '../Testimonial';
import NewsLetter from '../Newsletter/NewsLetter';
import { FaArrowRight } from 'react-icons/fa';
import { FaPython } from 'react-icons/fa';
import { SiScala } from 'react-icons/si';
import { SiJulia } from 'react-icons/si';
import { DiJavascript } from 'react-icons/di';
import wrangling from '../assets/wrangling.png';
import predictive from '../assets/chart.png';
import timeseries from '../assets/timeseries.png';
import statistics from '../assets/statistics.png';
import deep from '../assets/deep.png';
import cross from '../assets/cross.png';
import quality from '../assets/quality.png';
import mantenes from '../assets/mantenes.png';
import route from '../assets/route.png';
import customerexprience from '../assets/customerexprience.png';
import customerchurn from '../assets/customerchurn.png';
import salesprocess from '../assets/salesprocess.png';
import risk from '../assets/risk.png';
import custom from '../assets/custom.png';



export default function Datascience() {

    const [expand, updateExpanded] = useState(false);
    const webcontant = [
        { id: 1, name: 'Data Munging and Data Wrangling', para: 'Empower Advanced Analytics enabled Data Munging and Data Wrangling solutions for cleaning and transforming the data and preprocessing data before the process of Data Mining.', link: '#', icon: wrangling },
        { id: 2, name: 'Predictive Analytics Solutions', para: 'Predictive Analytics Solutions for enabling Decision-Making and Intelligence, Risks Analysis and Predictive Analytics Capabilities - Predictive Modeling and Data Mining.', link: '#', icon: predictive },
        { id: 3, name: 'Time Series Forecasting Analysis', para: 'Solutions for building time series forecasting models and automated time series forecasting applications using data and statistical analysis techniques.', link: '#', icon: timeseries },
        { id: 4, name: 'Operational Analytics Solutions', para: 'Enterprise Operational solutions for developing Predictive Analytics Capabilities and empowering enterprise performance analytics solutions.', link: '#', icon: statistics },
        { id: 5, name: 'Operational intelligence', para: 'Optimizing process performance due to detecting deviations and undesirable patterns and their root-cause analysis, performance prediction and forecasting.', link: '#', icon: deep },
        { id: 6, name: 'Supply chain management', para: 'Optimizing supply chain management with reliable demand predictions, inventory optimization recommendations, supplier- and risk assessment.', link: '#', icon: cross },
        { id: 7, name: 'Product quality', para: 'Proactively identifying the production process deviations affecting product quality and production process disruptions.', link: '#', icon: quality },
        { id: 8, name: 'Predictive maintenance', para: 'Monitoring machinery, identifying and reporting on patterns leading to pre-failure and failure states.', link: '#', icon: mantenes },
        { id: 9, name: 'Dymanic route optimization', para: 'ML-based recommendation of the optimal delivery route based on the analysis of vehicle maintenance data, real-time GPS data, route traffic data, road maintenance data, weather data, etc.', link: '#', icon: route },
        { id: 10, name: 'Customer experience personalization', para: 'Identifying customer behavior patterns and performing customer segmentation to build recommendation engines, design personalized services, etc.', link: '#', icon: customerexprience },
        { id: 11, name: 'Customer churn', para: 'Identifying potential churners by building predictions based on customers’ behavior.', link: '#', icon: customerchurn },
        { id: 12, name: 'Sales process optimization', para: 'Advanced lead and opportunity scoring, next-step sales recommendations, alerting on negative customer sentiments, etc.', link: '#', icon: salesprocess },
        { id: 13, name: 'Financial risk management', para: 'Forecasting project earnings, evaluating financial risks, assessing a prospect’s creditworthiness.', link: '#', icon: risk },
        { id: 14, name: 'Image analysis', para: 'Minimizing human error with automated visual inspection, facial or emotion recognition, grading, and counting.', link: '#', icon: custom }
    ]
    return (
        <section>
            <Container className="solutionoverview-content">
                <Title>Data Science</Title>
                <Col className='inerpage-title'>
                    <h4 className='section-title'>What we do</h4>
                </Col>
                <Row className="justify-content-md-center">
                    <Col md={5} className='solutionoverview-title'>
                        <Row>
                            <Col className='solutionover-hedding'>
                                <h4>Empowering Businesses with Qualitative and Quantitative Insights</h4>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='solutionover-para'>
                                <p>Data Science platform that allows analytics and AI teams across enterprises to rapidly develop and deploy custom machine learning models for scalable innovation to support the business. AIA is an online marketplace that provides a repository of reusable artefacts, which includes data science libraries, pre-trained models, algorithms, ready-to-use codes, datasets, templates, knowledge documents and solution recipes to expedite the lifecycle of AI experiments.</p>
                            </Col>
                        </Row>

                    </Col>
                    <Col md={5} className='solutionoverview-icons'>
                        <Row>
                            <Col md={6} className='solution-icon-one'>
                                <Row>
                                    <Col className='solution-enterprise-one'>
                                        <SiScala size={65} style={{ color: "white" }} />
                                        <h4>Scala</h4>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='solution-enterprise-two'>
                                        <FaPython size={65} style={{ color: "white" }} />
                                        <h4>Python</h4>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={6} className='solution-icon-two'>
                                <Row>
                                    <Col className='solution-enterprise-three'>
                                        <DiJavascript size={65} style={{ color: "white" }} />
                                        <h4>JavaScript</h4>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='solution-enterprise-fore'>
                                        <SiJulia size={65} style={{ color: "white" }} />
                                        <h4>Julia</h4>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
            <Container className="page-mid-container">
                <Row className="justify-content-md-center">
                    <Col md={10} className='page-mid-title'>
                        <h4>Data Science services for delivering the actionable and advanced analytics</h4>
                        <p>
                        Data science and machine learning are profoundly impacting businesses and are rapidly becoming critical for differentiation, and sometimes, survival. Businesses will continue to adapt to the latest competitive strategies to stay ahead of the curve every day. The two most striking features of this transition are increased automation of data processes and delivery of instantaneous analytics solutions. SGA’s data science consulting services help you frame complex business problems as machine learning or operations research problems to unveil better data science solutions. Latest data technologies are being used to solve business problems for a strategic advantage.
                        </p>
                    </Col>
                    <Col md={10} className='page-mid-contant'>
                        <Row style={{ justifyContent: "center" }}>
                            {
                                webcontant.map((item, i) => <Col sm={4} className="page-mid-box" key={i}>
                                    <img className='page-icon-img' src={item.icon} style={{ height: "13vh" }} />
                                    <h5>{item.name}</h5>
                                    <p>{item.para}</p>
                                    <Link className='glow-on-hover btn btn-default btn-oval btn-xl' as={Link} to="/contact" onClick={() => updateExpanded(false)}>Learn More  <FaArrowRight size={12} color='bisque' /></Link>
                                </Col>)
                            }
                        </Row>
                    </Col>
                    <Col md={10}>
                        <Link className='glow-on-hover btn btn-default btn-oval btn-xl' as={Link} to="/contact" onClick={() => updateExpanded(false)}>Let's Discuss  <FaArrowRight size={12} color='bisque' /></Link>
                    </Col>
                </Row>
            </Container>
            <TechExpert />
            <ChooseUs />
            <NewsLetter />
            <Testimonial />
        </section>
    );

}