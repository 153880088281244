import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Title from '../Header/Title';
import TechExpert from '../Home/TechExpert';
import ChooseUs from './ChooseUs';
import Testimonial from '../Testimonial';
import NewsLetter from '../Newsletter/NewsLetter';
import ProjectDiscuss from '../Contact/ProjectDiscuss';
import SolutionHire from '../Contact/SolutionHire';
import ProductHire from '../Contact/ProductHire';
import TrendingTech from '../Home/TrendingTech';
import TechnologyExpert from '../Home/TechnologyExpert';

export default function ProductEngineering() {

    return (
        <section>
            <Container fluid className="page-design-content" id="page">
                <Title>Product Engineering</Title>
                <Col className='inerpage-title'>
                    <h4 className='section-title'>What we do</h4>
                </Col>
                <Row className="justify-content-md-center">
                    <Col md={8} className='page-design-title'>
                        <h4>We are an inclusive digital hub for product design and development services.</h4>
                        <p>Product Engineering means the innovative technology and designs, tests and deployed to develop a software product. We deliver digital software products enabled with a seamless user experience and flexible customized architected designs to get the maximum expected outcome.</p>
                        <p>As the technology is transforming rapidly, so are the business requisites. MindInventory is a Digital Transformer that specializes in product design and development along with product engineering solutions.</p>
                    </Col>
                    <Col md={6} className='page-design-mid'>
                        <h5>Build Future Ready Software Products with End-to-end Product Engineering Services</h5>
                        <p>With our focused digital product development approach, our team of expert developers has the ability to handle the full product engineering lifecycle from conceptualization to deployment covering the full span of product development solutions. We proffer comprehensive product engineering and development services for different industries.</p>
                        <Col md={8} className='design-types'>
                            <h4>Experience Design for Product</h4>
                            <h4>Updating & Re-engineering the Product</h4>
                            <h4>Prototyping & Building the Roadmap</h4>
                            <h4>Planning & Strategy For Product Engineering</h4>
                            <h4>SaaS & Mobility Enablement</h4>                                                      
                        </Col>
                    </Col>
                </Row>
                <ProjectDiscuss />
                <ChooseUs />
                <TechnologyExpert />
                <TrendingTech />
                <ProductHire />
                <NewsLetter />
                <Testimonial />
                <SolutionHire />
            </Container>            
        </section>
    );

}