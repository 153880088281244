import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Title from '../Header/Title';
import TechExpert from '../Home/TechExpert';
import ChooseUs from './ChooseUs';
import Testimonial from '../Testimonial';
import NewsLetter from '../Newsletter/NewsLetter';
import SolutionHire from '../Contact/SolutionHire';
import ProductHire from '../Contact/ProductHire';
import TrendingTech from '../Home/TrendingTech';
import TechnologyExpert from '../Home/TechnologyExpert';
import ProjectDiscuss from '../Contact/ProjectDiscuss';
import { FaArrowRight } from 'react-icons/fa';
import { MdFastfood } from 'react-icons/md';
import { IoIosFitness } from 'react-icons/io';
import { MdCastForEducation } from 'react-icons/md';
import { FaNutritionix } from 'react-icons/fa';
import fooddelivery from '../assets/fooddelivery.png';
import fitness from '../assets/fitness.png';
import grocery from '../assets/grocery.png';
import helth from '../assets/helth.png';
import doctorapp from '../assets/doctorapp.png';
import education from '../assets/education.png';




export default function Webapplication() {

    const [expand, updateExpanded] = useState(false);
    const webcontant = [
        { id: 1, name: 'Food Delivery', para: 'Our developers have experience building websites for almost every industry and virtually every platform, including WordPress, Drupal, and Joomla.', link: '#', icon: fooddelivery },
        { id: 2, name: 'Fitness App', para: 'We work with modern frameworks like React.js, Angular, Vue.js, Nuxt, Next, and Gatsby to create next-generation user experiences that integrate and interact seamlessly with your database and APIs.', link: '#', icon: fitness },
        { id: 3, name: 'Grocery Delivery', para: 'Leverage our expertise with Cloud, DevOps, PHP, Node.js, Python, .NET, Java, and RoR to build custom, secure and reliable backends and APIs for web or mobile apps across multiple domains.', link: '#', icon: grocery },
        { id: 4, name: 'Health & Nutrition', para: 'If you have a store or need a new one, our web application development services can surely help you. We can create a store for you from scratch or leverage popular platforms such as WooCommerce, Shopify, or Magento.', link: '#', icon: helth },
        { id: 5, name: 'Doctor Appointment', para: 'As a web application development company, we have built all sorts of web applications, from online forms and workflows to whole management systems. We work on projects both big and small.', link: '#', icon: doctorapp },
        { id: 6, name: 'E-Learning', para: 'Do you have a manual process that you need to automate? Our web application development team can develop a customized, secure, and scalable system as per your business needs.', link: '#', icon: education }
       
    ]
    return (
        <section>
            <Container className="solutionoverview-content">
                <Title>Demand Soultion</Title>
                <Col className='inerpage-title'>
                    <h4 className='section-title'>What we do</h4>
                </Col>
                <Row className="justify-content-md-center">
                    <Col md={5} className='solutionoverview-title'>
                        <Row>
                            <Col className='solutionover-hedding'>
                                <h4>Revolutionize your Business with Viable on-demand app Solutions.</h4>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='solutionover-para'>
                                <p>The on-demand App Industry is on a continuous roll and is ascending to new heights with each passing day. Therefore, it is high time to provide a new dimension to your business by partnering a highly dexterous on-demand app development company that can help you reach a maximum number of targeted audiences and land you into the zone of profitability.</p>
                            </Col>
                        </Row>

                    </Col>
                    <Col md={5} className='solutionoverview-icons'>
                        <Row>
                            <Col md={6} className='solution-icon-one'>
                                <Row>
                                    <Col className='solution-enterprise-one'>
                                        <FaNutritionix size={65} style={{ color: "white" }} />
                                        <h4>Health & Nutrition</h4>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='solution-enterprise-two'>
                                        <IoIosFitness size={65} style={{ color: "white" }} />
                                        <h4>Fitness</h4>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={6} className='solution-icon-two'>
                                <Row>
                                    <Col className='solution-enterprise-three'>
                                        <MdCastForEducation size={65} style={{ color: "white" }} />
                                        <h4>Education</h4>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='solution-enterprise-fore'>
                                        <MdFastfood size={65} style={{ color: "white" }} />
                                        <h4>Food Delivery</h4>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
            <Container className="page-mid-container">
                <Row className="justify-content-md-center">
                    <Col md={10} className='page-mid-title'>
                        <h4>On-demand solutions we offer.</h4>
                        <p>
                            We provide wide range of on-demand services using the most advanced technology and proficiency of our team of veteran on demand app developers. We believe in sheer customer satisfaction and so are committed to offering the best user experience with the first-rate interface and exciting features.
                        </p>
                    </Col>
                    <Col md={10} className='page-mid-contant'>
                        <Row style={{ justifyContent: "center" }}>
                            {
                                webcontant.map((item, i) => <Col sm={4} className="page-mid-box" key={i}>
                                    <img className='page-icon-img' src={item.icon} style={{ height: "13vh" }} />
                                    <h5>{item.name}</h5>
                                    <p>{item.para}</p>
                                    <Link className='glow-on-hover btn btn-default btn-oval btn-xl' as={Link} to="/contact" onClick={() => updateExpanded(false)}>Learn More  <FaArrowRight size={12} color='bisque' /></Link>
                                </Col>)
                            }
                        </Row>
                    </Col>
                    <Col md={10}>
                        <Link className='glow-on-hover btn btn-default btn-oval btn-xl' as={Link} to="/contact" onClick={() => updateExpanded(false)}>Let's Discuss  <FaArrowRight size={12} color='bisque' /></Link>
                    </Col>
                </Row>
            </Container>
            <TrendingTech />
            <ProductHire />
            <TechnologyExpert />
            <ChooseUs />
            <ProjectDiscuss />
            <NewsLetter />
            <Testimonial />
            <SolutionHire />
        </section>
    );

}