import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Title from '../Header/Title';
import TechExpert from '../Home/TechExpert';
import ChooseUs from './ChooseUs';
import Testimonial from '../Testimonial';
import NewsLetter from '../Newsletter/NewsLetter';
import ProjectDiscuss from '../Contact/ProjectDiscuss';

export default function TypeScriptJs() {

    return (
        <section>
            <Container fluid className="page-design-content" id="page">
                <Title>Type Script Js</Title>
                <Col className='inerpage-title'>
                    <h4 className='section-title'>What we do</h4>
                </Col>
                <Row className="justify-content-md-center">
                    <Col md={8} className='page-design-title'>
                        <h4>TypeScript web development for the Solutions of tomorrow</h4>
                        <p>As a trusted TypeScript development company, we have helped several business owners leverage from the scalability and excellent digital solutions made with TypeScript. The modularity of this coding language stems from its object-oriented programming feature. This allows us to create objects in TypeScripe that cannot be created in JavaScript. We provide custom and business-oriented TypeScript development services. On the whole, TypeScript allows creating scalable applications, it is a good tool to use in large development teams and boasts with SOLID design patterns.</p>                        
                    </Col>
                    <Col md={6} className='page-design-mid'>
                        <h5>Our High Quality Type Script Development Services</h5>
                        <p>We are early adopters of Microsoft TypeScript (TS) to use with a range of JS based frameworks to implement flawless coding environments. This results in faster and quality delivery of websites and applications. Being a leading web development company, Webito Infotech has expert TypeScript developers to hire who explore, innovate and master the latest technologies to develop and deliver large scale applications.</p>
                        <Col md={8} className='design-types'>
                            <h4>Enterprise App Development</h4>
                            <h4>Web App Developments</h4>
                            <h4>TypeScript App Development</h4>
                            <h4>TypeScript Consultation</h4>
                            <h4>Maintenance & Support</h4>
                            <h4>JS to TS Migration</h4>                                                      
                        </Col>
                    </Col>
                </Row>
                <ProjectDiscuss />
                <ChooseUs />
                <TechExpert />
                <NewsLetter />
                <Testimonial />
            </Container>            
        </section>
    );

}