import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Title from '../Header/Title';
import TechExpert from '../Home/TechExpert';
import ChooseUs from './ChooseUs';
import Testimonial from '../Testimonial';
import NewsLetter from '../Newsletter/NewsLetter';
import ProjectDiscuss from '../Contact/ProjectDiscuss';

export default function Laravel() {

    return (
        <section>
            <Container fluid className="page-design-content" id="page">
                <Title>Laravel</Title>
                <Col className='inerpage-title'>
                    <h4 className='section-title'>What we do</h4>
                </Col>
                <Row className="justify-content-md-center">
                    <Col md={8} className='page-design-title'>
                        <h4>Our established teams ensure that you can rely on our adroit Laravel development services.</h4>
                        <p>MESD Technology offers a wide-range of flawless custom Laravel solutions to our clients. Laravel is an open source PHP based framework which offers a simple and robust structure making it very easy to use. Our Laravel developers provide top quality website structures. At CodeClouds, we work closely with our clients to build scalable web applications making use of Laravel to elevate your business through high quality web applications.</p>                        
                    </Col>
                    <Col md={6} className='page-design-mid'>
                        <h5>Best-In-Class Laravel Development Services for Better Business Growth</h5>
                        <p>Want to move your existing project to the Laravel framework? We have years of experience migrating to Laravel from other frameworks or vanilla PHP. Laravel offers superior code foundations, easy maintainability and resilient features, it has proven its position amongst the best PHP frameworks available. Our expert Laravel developers will help you structure a detailed approach for developing Laravel powered websites and applications for your business. Hire CodeClouds’ expert developers to get top quality solutions at competitive prices.</p>
                        <Col md={8} className='design-types'>
                            <h4>Laravel Application Development</h4>
                            <h4>Lumen based Web Services & API Development</h4>
                            <h4>SaaS Application Development</h4>
                            <h4>Browser Testing & Automation</h4>
                            <h4>Laravel based Website Development</h4>
                            <h4>Migrate to Laravel</h4>
                            <h4>Laravel Consulting</h4>                                                       
                        </Col>
                    </Col>
                </Row>
                <ProjectDiscuss />
                <ChooseUs />
                <TechExpert />
                <NewsLetter />
                <Testimonial />
            </Container>            
        </section>
    );

}