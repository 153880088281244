import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import developer from '../assets/developer.png';
import deadline from '../assets/deadline.png';
import buffer from '../assets/buffer.png';
import skills from '../assets/skills.png';
import happy from '../assets/happy.png';
import transparency from '../assets/transparency.png';
import schedule from '../assets/schedule.png';
import discussion from '../assets/discussion.png';
import drones from './LottieJson/drones.json';
import teamwork from './LottieJson/teamwork.json';
import Lottie from 'react-lottie';


export default function Benefits() {
    const perks = [
        { id: 1, name: 'Dedicated Developer', image: developer },
        { id: 2, name: 'Your Deadlines = Our Deadlines', image: deadline },
        { id: 3, name: '10 Hours Buffer', image: buffer },
        { id: 4, name: 'Strong Development Skillset', image: skills },
        { id: 5, name: 'Responsibility', image: happy },
        { id: 6, name: 'Transparency', image: transparency },
        { id: 7, name: 'Shortlisting & Scheduling', image: schedule },
        { id: 8, name: 'Technical Discussion', image: discussion }
    ]
    const dronesanimation = {
        loop: true,
        autoplay: true,
        animationData: drones,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    const teamanimation = {
        loop: true,
        autoplay: true,
        animationData: teamwork,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };


    return (
        <section>
            <Container className="benefits-content">
            <h4 className='section-title'>Why Hiring Us</h4>
                <Row style={{ justifyContent: "center", paddingBottom: "50px", paddingTop: "20px" }}>                    
                    {
                        perks.map((item) => <Col xs={3} className="benefits-icons">
                            <img src={item.image} style={{ height: "10vh" }} />
                            <h5>{item.name}</h5>
                        </Col>)
                    }
                </Row>
                <Row>
                    <h4 className='section-title'>Best Outsource Development</h4>
                    <Col sm={8} className='outsource-contant'>
                        <Row>
                            <Col xs={6} className='outsource-grid'>
                                <h4>Industry Leading Solutions</h4>
                                <p>We can help you expand your business, upgrade technology and break through roadblocks with world-class solutions.</p>
                            </Col>
                            <Col xs={6} className='outsource-grid'>
                                <h4>Cost-Friendly Development</h4>
                                <p>Whether you’re a small online business or large enterprise, our hiring model accommodates budgets of any size.</p>
                            </Col>
                            <Col xs={6} className='outsource-grid'>
                                <h4>Transparent Communication</h4>
                                <p>You’re kept in the loop throughout your project. We value the importance of honest and clear communication.</p>
                            </Col>
                            <Col xs={6} className='outsource-grid'>
                                <h4>Obligation-Free Hiring Models</h4>
                                <p>When you hire development experts from CodeClouds, you are free from any hassle of contracts or restraints.</p>
                            </Col>
                            <Col xs={6} className='outsource-grid'>
                                <h4>Secure Development Environment</h4>
                                <p>We value security and privacy as much as you do. Your information is safe and secure in our state-of-the-art systems.</p>
                            </Col>
                            <Col xs={6} className='outsource-grid'>
                                <h4>Agile & Efficient Development</h4>
                                <p>We consider all facets of your project allowing us to identify any problems and solve them before they happen.</p>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={4} className='outsource-img'>
                        <Row>
                            <Col className='outsource-drone'>

                                <Lottie
                                    options={dronesanimation}
                                    height={100}
                                    width={100}
                                />

                            </Col>
                            <Col className='outsource-team'>

                                <Lottie
                                    options={teamanimation}
                                    height={370}
                                    width={370}
                                />

                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}