import React, { useState } from 'react';
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';
import { FaArrowRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import angular from './assets/angular.svg';
import laravel from './assets/laravel.svg';
import nodejs from './assets/nodejs.svg';
import python from './assets/python.svg';
import reactnative from './assets/react-native.svg';
import vuejs from './assets/vuejs.svg';
import uiuxweb from './assets/ui-ux-web.svg';
import webinteraction from './assets/web-interaction.svg';
import responsivedesign from './assets/responsive-design.svg';
// import Webparalax from './Webparalax';



export default function Webdevelopment() {
    const [expand, updateExpanded] = useState(false);
    const frontend = [
        { id: 1, title: 'AngularJs Development', link: '/angularjs', icon: angular },
        { id: 2, title: 'ReactJs Development', link: '/reactjs', icon: reactnative },
        { id: 3, title: 'VueJs Development', link: '/vuejs', icon: vuejs },
        { id: 4, title: 'TypeScript Development', link: '/typescript', icon: angular }
    ]
    const backend = [
        { id: 1, title: 'NodeJs Development', link: '/nodejs', icon: nodejs },
        { id: 2, title: 'Laravel Development', link: '/laravel', icon: laravel },
        { id: 3, title: 'Python Development', link: '/python', icon: python }
    ]
    const design = [
        { id: 1, title: 'UI/UX Design', link: '#', icon: uiuxweb },
        { id: 2, title: 'Web Interaction', link: '#', icon: webinteraction },
        { id: 3, title: 'Responsive Design', link: '#', icon: responsivedesign }
    ]

    return (
        <section>
            <Container className='webdevelop-content'>
                <Row className="justify-content-md-center">
                    <Col md={12} className="web-develop">
                        <Row className="justify-content-md-center">
                            <Col md={8} className='web-develop-content'>
                                <Row>
                                    <Col md={6} className='web-develop-title'>
                                        <h4>Web</h4>
                                    </Col>
                                    <Col md={6} className='web-develop-link'>
                                        <Link className='glow-on-hover btn btn-default btn-oval btn-x' as={Link} to="/webapplication" onClick={() => updateExpanded(false)}>Explore all Web Services</Link>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='web-develop-tabs'>
                                        <Tabs defaultActiveKey="front" id="uncontrolled-tab-example" className="mb-3 web-tab-list">
                                            <Tab eventKey="front" title="Front-end">
                                                <Col md={12} className='web-develop-front'>
                                                    <Row>
                                                        {
                                                            frontend.map((item, i) => <Col sm={3} className="web-develop-front-list" key={i}>
                                                                <img className='web-develop-icon fluid' src={item.icon} style={{ height: "15vh" }} />
                                                                <h5>{item.title}</h5>
                                                                <Link className='btn btn-default btn-circle btn-xl' as={Link} to={item.link} onClick={() => updateExpanded(false)}><FaArrowRight size={20} color='bisque' /></Link>
                                                            </Col>)
                                                        }
                                                    </Row>
                                                </Col>
                                            </Tab>
                                            <Tab eventKey="back" title="Back-end">
                                                <Col md={12} className='web-develop-back'>
                                                    <Row>
                                                        {
                                                            backend.map((item, i) => <Col sm={3} className="web-develop-front-list" key={i}>
                                                                <img className='web-develop-icon fluid' src={item.icon} style={{ height: "15vh" }} />
                                                                <h5>{item.title}</h5>
                                                                <Link className='btn btn-default btn-circle btn-xl' as={Link} to={item.link} onClick={() => updateExpanded(false)}><FaArrowRight size={20} color='bisque' /></Link>
                                                            </Col>)
                                                        }
                                                    </Row>
                                                </Col>
                                            </Tab>
                                            <Tab eventKey="design" title="Design">
                                                <Col md={12} className='web-develop-design'>
                                                    <Row>
                                                        {
                                                            design.map((item, i) => <Col sm={3} className="web-develop-front-list" key={i}>
                                                                <img className='web-develop-icon fluid' src={item.icon} style={{ height: "15vh" }} />
                                                                <h5>{item.title}</h5>
                                                                <Link className='btn btn-default btn-circle btn-xl' as={Link} to={item.link} onClick={() => updateExpanded(false)}><FaArrowRight size={20} color='bisque' /></Link>
                                                            </Col>)
                                                        }
                                                    </Row>
                                                </Col>
                                            </Tab>
                                        </Tabs>
                                    </Col>
                                </Row>
                                <Col md={6} className='mobile-display-link'>
                                    <Link className='glow-on-hover btn btn-default btn-oval btn-x' as={Link} to="/contact" onClick={() => updateExpanded(false)}>Explore all Web Services</Link>
                                </Col>
                            </Col>
                            <Col md={4} className='web-develop-para'>
                                {/* <Webparalax /> */}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    )

}