import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Title from '../Header/Title';
import TechExpert from '../Home/TechExpert';
import ChooseUs from './ChooseUs';
import Testimonial from '../Testimonial';
import NewsLetter from '../Newsletter/NewsLetter';
import ProjectDiscuss from '../Contact/ProjectDiscuss';

export default function NodeJs() {

    return (
        <section>
            <Container fluid className="page-design-content" id="page">
                <Title>Node Js</Title>
                <Col className='inerpage-title'>
                    <h4 className='section-title'>What we do</h4>
                </Col>
                <Row className="justify-content-md-center">
                    <Col md={8} className='page-design-title'>
                        <h4>As a best Node.js development company, we create fast, scalable & real time applications.</h4>
                        <p>Stay ahead of your competitors by growing your business with our secure, fast, and real-time node.js development services. We make sure to develop feature-rich, scalable, and high-end node.js backend solutions and server-side apps.</p>                        
                    </Col>
                    <Col md={6} className='page-design-mid'>
                        <h5>Our High Quality Node Js Development Services</h5>
                        <p>Node.js developers are responsible for the server-side logic that powers web applications and mobile apps. From database migrations to API integrations to set up the server-side technologies that make an application work, a Node.js web developer is a quintessential role that you want to get your project off the ground.</p>
                        <Col md={8} className='design-types'>
                            <h4>Custom Development</h4>
                            <h4>Dedicated Node.js Developers</h4>
                            <h4>Real-time Applications</h4>
                            <h4>Node.js for Mobile & Web Apps</h4>
                            <h4>Re-Engineering</h4>
                            <h4>Application Modernization</h4>
                            <h4>API Development for Legacy Applications</h4>                                                     
                        </Col>
                    </Col>
                </Row>
                <ProjectDiscuss />
                <ChooseUs />
                <TechExpert />
                <NewsLetter />
                <Testimonial />
            </Container>            
        </section>
    );

}