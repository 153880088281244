import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Title from '../Header/Title';
import TechExpert from '../Home/TechExpert';
import ChooseUs from './ChooseUs';
import Testimonial from '../Testimonial';
import NewsLetter from '../Newsletter/NewsLetter';
import SolutionHire from '../Contact/SolutionHire';
import ProjectDiscuss from '../Contact/ProjectDiscuss';
import ProductHire from '../Contact/ProductHire';
import { FaArrowRight } from 'react-icons/fa';
import { FaNodeJs } from 'react-icons/fa';
import { FaAngular } from 'react-icons/fa';
import { FaReact } from 'react-icons/fa';
import { FaLaravel } from 'react-icons/fa';
import Angularjs from '../services/assets/angular.svg';
import Nodejs from '../services/assets/nodejs.svg';
import api from '../assets/api.png';
import backend from '../assets/backend.png';
import business from '../assets/business.png';
import cms from '../assets/cms.png';
import commerce from '../assets/commerce.png';
import designer from '../assets/designer.png';
import fontend from '../assets/fontend.png';
import softwate from '../assets/softwate.png';
import webapplication from '../assets/webapplication.png';
import ecommerce from '../assets/commerce.png';


export default function Webapplication() {

    const [expand, updateExpanded] = useState(false);
    const webcontant = [
        { id: 1, name: 'CMS Based Websites', para: 'Our developers have experience building websites for almost every industry and virtually every platform, including WordPress, Drupal, and Joomla.', link: '#', icon: cms },
        { id: 2, name: 'Front-end Development', para: 'We work with modern frameworks like React.js, Angular, Vue.js, Nuxt, Next, and Gatsby to create next-generation user experiences that integrate and interact seamlessly with your database and APIs.', link: '#', icon: fontend },
        { id: 3, name: 'Backend Development', para: 'Leverage our expertise with Cloud, DevOps, PHP, Node.js, Python, .NET, Java, and RoR to build custom, secure and reliable backends and APIs for web or mobile apps across multiple domains.', link: '#', icon: backend },
        { id: 4, name: 'eCommerce Applications', para: 'If you have a store or need a new one, our web application development services can surely help you. We can create a store for you from scratch or leverage popular platforms such as WooCommerce, Shopify, or Magento.', link: '#', icon: ecommerce },
        { id: 5, name: 'Web Applications', para: 'As a web application development company, we have built all sorts of web applications, from online forms and workflows to whole management systems. We work on projects both big and small.', link: '#', icon: webapplication },
        { id: 6, name: 'Business Automation', para: 'Do you have a manual process that you need to automate? Our web application development team can develop a customized, secure, and scalable system as per your business needs.', link: '#', icon: business },
        { id: 7, name: 'API Development & Documentation', para: 'We can help you if you need to build or consume an API. Our teams have worked with all sort of APIs, including Voice, Video, Payment, Cloud, Accounting, etc', link: '#', icon: api },
        { id: 8, name: 'Software Development', para: 'Our engineering team has a lot of experience developing complex web-based business systems with multiple users and roles and that connect with CRM, Accounting, Inventory, and Other Systems.', link: '#', icon: softwate },
        { id: 9, name: 'Dedicated Web Developer', para: 'Hiring dedicated Web developers remotely can help your business scale faster. You can hire dedicated Web developers 40h/week at a fixed monthly rate.', link: '#', icon: designer }
    ]
    return (
        <section>
            <Container className="solutionoverview-content">
                <Title>Web Application</Title>
                <Col className='inerpage-title'>
                    <h4 className='section-title'>What we do</h4>
                </Col>
                <Row className="justify-content-md-center">
                    <Col md={5} className='solutionoverview-title'>
                        <Row>
                            <Col className='solutionover-hedding'>
                                <h4>Top Web Development Company Specialized to Create Robust Website and Applications.</h4>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='solutionover-para'>
                                <p>As a leading web development company, we always create robust, secure, custom web application with high scalability. According to the need of product, we use open source technology to develop a product with high quality standard.</p>
                            </Col>
                        </Row>

                    </Col>
                    <Col md={5} className='solutionoverview-icons'>
                        <Row>
                            <Col md={6} className='solution-icon-one'>
                                <Row>
                                    <Col className='solution-enterprise-one'>
                                        <FaLaravel size={65} style={{ color: "white" }} />
                                        <h4>Laravel</h4>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='solution-enterprise-two'>
                                        <FaNodeJs size={65} style={{ color: "white" }} />
                                        <h4>NodeJs</h4>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={6} className='solution-icon-two'>
                                <Row>
                                    <Col className='solution-enterprise-three'>
                                        <FaAngular size={65} style={{ color: "white" }} />
                                        <h4>AngularJs</h4>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='solution-enterprise-fore'>
                                        <FaReact size={65} style={{ color: "white" }} />
                                        <h4>ReactJs</h4>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
            <Container className="page-mid-container">
                <Row className="justify-content-md-center">
                    <Col md={10} className='page-mid-title'>
                        <h4>Web Application Development Services</h4>
                        <p>
                            Regardless of how complex or even small your needs are, our dedicated in-house team of web developers and project managers will ensure that the job gets done with the highest level of professionalism and most cost affordably.
                        </p>
                    </Col>
                    <Col md={10} className='page-mid-contant'>
                        <Row style={{ justifyContent: "center" }}>
                            {
                                webcontant.map((item, i) => <Col sm={4} className="page-mid-box" key={i}>
                                    <img className='page-icon-img' src={item.icon} style={{ height: "13vh" }} />
                                    <h5>{item.name}</h5>
                                    <p>{item.para}</p>
                                    <Link className='glow-on-hover btn btn-default btn-oval btn-xl' as={Link} to="/contact" onClick={() => updateExpanded(false)}>Learn More  <FaArrowRight size={12} color='bisque' /></Link>
                                </Col>)
                            }
                        </Row>
                    </Col>
                    <Col md={10}>
                        <Link className='glow-on-hover btn btn-default btn-oval btn-xl' as={Link} to="/contact" onClick={() => updateExpanded(false)}>Let's Discuss  <FaArrowRight size={12} color='bisque' /></Link>
                    </Col>
                </Row>
            </Container>
            <ProjectDiscuss />
            <TechExpert />
            <ChooseUs />
            <ProductHire />
            <NewsLetter />
            <Testimonial />
            <SolutionHire />          
        </section>
    );

}