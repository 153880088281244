import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Title from '../Header/Title';
import TechExpert from '../Home/TechExpert';
import ChooseUs from './ChooseUs';
import Testimonial from '../Testimonial';
import NewsLetter from '../Newsletter/NewsLetter';
import SolutionHire from '../Contact/SolutionHire';
import ProjectDiscuss from '../Contact/ProjectDiscuss';
import ProductHire from '../Contact/ProductHire';
import { FaArrowRight } from 'react-icons/fa';
import { SiIonic } from 'react-icons/si';
import { FaAngular } from 'react-icons/fa';
import { FaReact } from 'react-icons/fa';
import { SiXamarin } from 'react-icons/si';
import cross from '../assets/cross.png';
import enterprise from '../assets/enterprise.png';
import consumer from '../assets/consumer.png';
import native from '../assets/native.png';
import watches from '../assets/watches.png';
import iot from '../assets/iot.png';




export default function Mobileapp() {

    const [expand, updateExpanded] = useState(false);
    const webcontant = [
        { id: 1, name: 'Enterprise Apps', para: 'Improved efficiency, faster communication and a greater ROI. That’s just some of what we can make your backend enterprise apps do for you. Our team develops solutions for your entire organization through consistent, scalable and adaptable platforms.', link: '#', icon: enterprise },
        { id: 2, name: 'Consumer Apps', para: 'We develop apps that will solve your specific problems, based on a deep and thorough understanding of your personal needs. Just tell us you have to have, and we’ll give it to you. Your app will be customized, developed, and handcrafted from the ground up.', link: '#', icon: consumer },
        { id: 3, name: 'Native App Development', para: 'Improved efficiency, faster communication and a greater ROI. That’s just some of what we can make your backend enterprise apps do for you. Our team develops solutions for your entire organization through consistent, scalable and adaptable platforms.', link: '#', icon: native },
        { id: 4, name: 'Cross-platform App Development', para: 'We develop apps that will solve your specific problems, based on a deep and thorough understanding of your personal needs. Just tell us you have to have, and we’ll give it to you. Your app will be customized, developed, and handcrafted from the ground up.', link: '#', icon: cross },
        { id: 5, name: 'Wearable App Development', para: 'Improved efficiency, faster communication and a greater ROI. That’s just some of what we can make your backend enterprise apps do for you. Our team develops solutions for your entire organization through consistent, scalable and adaptable platforms.', link: '#', icon: watches },
        { id: 6, name: 'IoT App Development', para: 'We develop apps that will solve your specific problems, based on a deep and thorough understanding of your personal needs. Just tell us you have to have, and we’ll give it to you. Your app will be customized, developed, and handcrafted from the ground up.', link: '#', icon: iot }
        
        
    ]
    return (
        <section>
            <Container className="solutionoverview-content">
                <Title>Mobile App</Title>
                <Col className='inerpage-title'>
                    <h4 className='section-title'>What we do</h4>
                </Col>
                <Row className="justify-content-md-center">
                    <Col md={5} className='solutionoverview-title'>
                        <Row>
                            <Col className='solutionover-hedding'>
                                <h4>We are Mobile App Development Company to Design & Develop Your Custom Mobile Application.</h4>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='solutionover-para'>
                                <p>Mobile app development is indeed nowadays necessary for all the business. We, as a mobile application development company can provide high quality standard mobile app of any category. We have worked with diverse brands, organizations, startups and individuals to create powerful apps from excellent idea.</p>
                            </Col>
                        </Row>

                    </Col>
                    <Col md={5} className='solutionoverview-icons'>
                        <Row>
                            <Col md={6} className='solution-icon-one'>
                                <Row>
                                    <Col className='solution-enterprise-one'>
                                        <SiIonic size={65} style={{ color: "white" }} />
                                        <h4>Ionic</h4>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='solution-enterprise-two'>
                                        <SiXamarin size={65} style={{ color: "white" }} />
                                        <h4>Xamarin</h4>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={6} className='solution-icon-two'>
                                <Row>
                                    <Col className='solution-enterprise-three'>
                                        <FaAngular size={65} style={{ color: "white" }} />
                                        <h4>AngularJs</h4>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='solution-enterprise-fore'>
                                        <FaReact size={65} style={{ color: "white" }} />
                                        <h4>React Native</h4>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
            <Container className="page-mid-container">
                <Row className="justify-content-md-center">
                    <Col md={10} className='page-mid-title'>
                        <h4>Beautiful Mobile Apps For iOS & Android</h4>
                        <p>
                        If you’re serious about getting apps to improve the way your business operates, or launching high-end and beautiful mobile apps for iOS and Android, then you should know that there’s only one place you can get a dedicated team of skilled developers working exclusively on your vision.
                        </p>
                    </Col>
                    <Col md={10} className='page-mid-contant'>
                        <Row style={{ justifyContent: "center" }}>
                            {
                                webcontant.map((item, i) => <Col sm={4} className="page-mid-box" key={i}>
                                    <img className='page-icon-img' src={item.icon} style={{ height: "13vh" }} />
                                    <h5>{item.name}</h5>
                                    <p>{item.para}</p>
                                    <Link className='glow-on-hover btn btn-default btn-oval btn-xl' as={Link} to="/contact" onClick={() => updateExpanded(false)}>Learn More  <FaArrowRight size={12} color='bisque' /></Link>
                                </Col>)
                            }
                        </Row>
                    </Col>
                    <Col md={10}>
                        <Link className='glow-on-hover btn btn-default btn-oval btn-xl' as={Link} to="/contact" onClick={() => updateExpanded(false)}>Let's Discuss  <FaArrowRight size={12} color='bisque' /></Link>
                    </Col>
                </Row>
            </Container>
            <ProjectDiscuss />
            <TechExpert />
            <ChooseUs />
            <ProductHire />
            <NewsLetter />
            <Testimonial />
            <SolutionHire />
        </section>
    );

}