import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import {Link} from 'react-router-dom';
import { FaArrowRight } from 'react-icons/fa';

export default function ProductHire() {
    const [expand, updateExpanded] = useState(false);
    return (
        <section>
            <Container className='product-hire-content'>
                <Row className="justify-content-md-center">                
                    <Col md={4} className='product-hire'> 
                        <h4>Can’t find <span>CRM</span> service you are looking for?</h4>
                        <Link className='glow-on-hover btn btn-default btn-oval btn-xl' as={Link} to="/crm" onClick={() => updateExpanded(false)}>Explore Service  <FaArrowRight size={12} color='bisque' /></Link>
                    </Col>
                    <Col md={4} className='product-hire-work'> 
                        <h4>Want to hire a resource to <span>AI</span> work with you?</h4>
                        <Link className='glow-on-hover btn btn-default btn-oval btn-xl' as={Link} to="/contact" onClick={() => updateExpanded(false)}>Let's Discuss  <FaArrowRight size={12} color='bisque' /></Link>
                    </Col>
                </Row>
            </Container>
        </section>
    );

}